import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ButtonsGroup from '../ButtonsGroup';
import Select from 'react-select';
import Breadcrumb from '../Breadcrumb';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import './DataTables.css';
import { accountManagers } from '../../utils';

// Err Forward ref: Probably src/pages/Groups ref
// The ref could it be a only ref in the top level not props
const DataTables = ({
  data,
  columns,
  view,
  viewState,
  buttonsCallback = () => {},
  userRole,
  noDataIndication,
  expandRow,
  rowEvents,
  alternTitle,
  title = null,
  handleModal,
  showTotal,
  rowStyle,
  loading,
  children
}) => {
  const [filter, setFilter] = useState(null);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (data && filteredData && filter?.value !== '0') {
      const filteredData = data.filter((item) => {
        return item.accountOwner === filter?.value;
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData(data);
    }
  }, [filter]);

  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: '15',
        value: 15
      },
      {
        text: '30',
        value: 30
      },
      {
        text: '45',
        value: 45
      },
      {
        text: '60',
        value: 60
      }
    ]
  });

  const MySearch = (props) => {
    const handleSearch = (e) => props.onSearch(e.target.value);
    return (
      <div className="content-header">
        <div className="content-search">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="rgb(131, 146, 165)"
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
            />
          </svg>
          <input
            type="search"
            className="form-control"
            placeholder={
              alternTitle ? `Search ${alternTitle}` : 'Search clients'
            }
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
    );
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={filter?.value === '0' || !filter ? data : filteredData}
        columns={columns}
        rowStyle={{ backgroundColor: 'white' }}
        bootstrap4
        search>
        {(props) => (
          <>
            <MySearch {...props.searchProps} />
            <div className="content-body px-16">
              <div class="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                <div class="d-flex flex-column">
                  <Breadcrumb
                    items={[
                      { label: 'Dashboard', href: '/clients', active: false },
                      { label: title, active: true }
                    ]}
                  />
                  <h4 class="mg-b-0 tx-spacing--1">
                    {title}{' '}
                    {data?.length > 0 && !filter && view === undefined
                      ? `(${data?.length})`
                      : null}
                    {filter?.value !== '0' && !filter
                      ? null
                      : `(${filteredData?.length})`}
                  </h4>
                </div>

                <div class="d-flex align-items-center ms-auto">
                  {view === undefined && (
                    <div
                      style={{ marginRight: '10px' }}
                      className="d-flex align-items-center">
                      <label className="mx-2" style={{ color: '#8392a5' }}>
                        Account Manager:
                      </label>
                      <Select
                        isDisabled={loading}
                        styles={{
                          ...customStyles,
                          ...{
                            container: (provided) => ({
                              ...provided,
                              width: '120px'
                            })
                          }
                        }}
                        value={
                          filter
                            ? {
                                value: filter?.value,
                                label: accountManagers.find(
                                  (item) => item.id === filter?.value
                                ).full_name
                              }
                            : { value: '0', label: 'All' }
                        }
                        onChange={(val) => {
                          handleFilterChange({
                            value: val.value
                          });
                        }}
                        defaultValue={{ value: '0', label: 'All' }}
                        options={accountManagers.map((option, idx) => ({
                          value: option.id,
                          label: option.full_name
                        }))}
                      />
                    </div>
                  )}

                  <div class="d-none d-md-block">
                    <ButtonsGroup
                      view={view}
                      viewState={viewState}
                      connectCallback={buttonsCallback}
                      userRole={userRole}
                      handleModal={handleModal}
                      loading={loading}
                      showTotal={showTotal}
                    />
                  </div>
                </div>
              </div>

              {children}
              <div className="table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  pagination={pagination}
                  rowEvents={rowEvents}
                  hover
                  borderless
                  rowStyle={rowStyle}
                  condensed
                  noDataIndication={noDataIndication}
                  wrapperClasses="mg-b-0"
                  expandRow={expandRow}
                  filter={filterFactory()}
                />
              </div>
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
};
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '60px'
  }),
  control: (provided) => ({
    ...provided,
    maxHeight: '25px',
    minHeight: '8px'
  }),
  menu: (provided) => ({
    ...provided
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3b5998' : 'white'
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '2px',
    paddingLeft: '1px'
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none'
  })
};
export default DataTables;
