import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TabPanel from './TabPanel';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const titlesNames = [
  'App Gallery',
  'Content Gallery',
  'Client Gallery',
  'Pixabay Gallery',
  'Pexels Gallery',
  'Unsplash Gallery'
];

const TabMenu = (props) => {
  const { children, state } = props;
  const { value, handleIndexChange } = state;

  const handlePrev = () => {
    if (value > 0) handleIndexChange(null, value - 1);
  };

  const handleNext = () => {
    if (value < children.length - 1) handleIndexChange(null, value + 1);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <IconButton onClick={handlePrev} disabled={value === 0}>
          <ArrowBackIosIcon />
        </IconButton>

        <Tabs
          value={Math.max(0, Math.min(value, children.length - 1))}
          onChange={(event, newValue) => handleIndexChange(event, newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable auto tabs example">
          {children.map((element, index) => (
            <Tab key={index} label={titlesNames[index]} {...a11yProps(index)} />
          ))}
        </Tabs>

        <IconButton
          onClick={handleNext}
          disabled={value === children.length - 1}>
          <ArrowForwardIosIcon />
        </IconButton>
      </AppBar>

      {children.map((element, index) => (
        <TabPanel key={index} value={value} index={index}>
          {element}
        </TabPanel>
      ))}
    </>
  );
};

export default TabMenu;
