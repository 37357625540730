import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTables from '../../components/DataTables';
import NewFacebookPageConnection from '../../components/NewFacebookPageConnection';
import NewZohoConnection from '../../components/NewZohoConnection';
import NewGoogleConnection from '../../components/NewGoogleConnection';
import NewInstagramConnection from '../../components/NewInstagramConnection';
import NewPost from '../../components/NewPost';
import { setSelectedClient } from '../../store/slices/selectedClient';
import AppRegister from '../../components/AppRegister';
import BulkModal from '../../components/BulkModal';
import { getClients } from '../../store/slices/clients';
import { authSelector } from '../../store/slices/auth';
import { deleteClient } from '../../store/slices/clients';
import { showLoading, hideLoading } from '../../store/slices/loadingState';
import { unwrapResult } from '@reduxjs/toolkit';
import { accountManagers } from '../../utils';

const Dashboard = (props) => {
  const facebookModalRef = useRef(null);
  const instagramModalRef = useRef(null);
  const googleModalRef = useRef(null);
  const zohoModalRef = useRef(null);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentClientTimeZone, setCurrentClientTimeZone] = useState(null);
  const [currentClientBestTimes, setCurrentClientBestTimes] = useState(null);
  const [currentClientMergeFields, setCurrentClientMergeFields] =
    useState(null);
  const [currentClientSocialNetworks, setCurrentClientSocialNetworks] =
    useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const instagramPages = [];
  const [appRegister, setAppRegister] = useState({
    show: false,
    data: null
  });
  const [loading, setLoading] = useState(false);
  const [newPost, setNewPost] = useState({ show: false });
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const dispatch = useDispatch();
  const { userInfo } = useSelector(authSelector);

  const accountStatusColumn = (cell, row) => {
    if (row.id) {
      return (
        <div>
          <span>{row.accountStatus}</span>
        </div>
      );
    }
    return <div></div>;
  };

  const accountManagersColumn = (cell, row) => {
    const accountManager = accountManagers.find(
      (manager) => manager.id === row.accountOwner
    );

    if (accountManager) {
      return (
        <div>
          <span>{accountManager.full_name}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const validNetworks = (socialnetworks) => {
    return (
      socialnetworks.googleMyBusiness ||
      socialnetworks.facebook ||
      socialnetworks.instagram
    );
  };
  const callBackClose = () => {
    setNewPost({ show: false });
  };

  const handleCalendarClick = (row) => {
    props.dispatch(setSelectedClient(row));
    let newUrl = row.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    props.history.push(`/calendar/${newUrl}`);
  };

  const clientActions = (cell, row) => {
    let newUrl = row.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');

    if (row.id) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <nav className="nav nav-icon-only">
            <button
              className=" button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Go to client calendar"
              onClick={() => handleCalendarClick(row)}>
              <i
                className="fa fa-fw fa-calendar-alt"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '24px' }}
              />
            </button>
            <Link
              onClick={() => props.dispatch(setSelectedClient(row))}
              onMouseDown={() => props.dispatch(setSelectedClient(row))}
              to={{
                pathname: `/client/${newUrl}`,
                state: {
                  clientId: row.id,
                  clientGroups: row.clientGroups,
                  socialNetworks: row['clientSocialNetworks']
                }
              }}>
              <button
                className="button-like-a"
                type="button"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Got to client's profile">
                <i
                  className="fa fa-fw fa-pencil-alt"
                  style={{ color: 'rgb(131, 146, 165)', fontSize: '22px' }}
                />
              </button>
            </Link>
            <button
              className="button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Delete client"
              onClick={() => handleDelete(row)}>
              <i
                className="fa fa-fw fa-trash-alt"
                style={{ color: 'rgb(131, 146, 165)', fontSize: '22px' }}
              />
            </button>
          </nav>
        </div>
      );
    }
  };

  const handleDelete = (selectedClient) => {
    console.log('selectedClient: ', selectedClient);
    let newGroups = [];

    if (Array.isArray(selectedClient.clientGroups)) {
      selectedClient.clientGroups.forEach((clientGroup) => {
        newGroups.push({ id: clientGroup.id });
      });
    } else if (selectedClient.clientGroups) {
      newGroups.push({ id: selectedClient.clientGroups });
    }

    Swal.fire({
      title: 'Delete Client',
      text: 'Are you sure you want delete',
      showCloseButton: true,
      showCancelButton: true
    }).then(async (res) => {
      if (res.value) {
        try {
          const payload = {
            groupsDelete: newGroups,
            clientId: selectedClient.id,
            deletedBy: {
              displayName: userInfo?.displayName,
              email: userInfo?.email,
              role: userInfo?.role
            }
          };
          let res = await dispatch(deleteClient(payload));
          res = unwrapResult(res);

          if (res) {
            Swal.fire({
              title: 'Success!',
              text: 'Client Deleted.',
              showCloseButton: false,
              allowOutsideClick: false
            }).then((res) => {
              if (res.value) {
                window.location.reload();
              }
            });
          } else {
            Swal.fire({
              title: 'Warning!',
              text: 'Client is deleted already.',
              showCloseButton: false,
              allowOutsideClick: false
            }).then((res) => {
              if (res.value) {
                window.location.reload();
              }
            });
          }
        } catch (e) {
          console.error(e);
        }
      }
    });
  };

  const renderZohoIcon = (active) => {
    let iconStyle = {
      width: 23,
      height: 23,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/logo-crm.png`
          : `${process.env.PUBLIC_URL}/assets/img/logo-crm-disconnected.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const clientSocialNetworks = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            {row.clientSocialNetworks ? (
              <>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newFacebookPageConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.facebook
                        ? { color: '#3b5998' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      const modalElement = facebookModalRef.current;
                      const bootstrapModal = new window.bootstrap.Modal(
                        modalElement
                      );
                      bootstrapModal.show();
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-facebook" />
                    {row.clientSocialNetworks.fbFailing &&
                    row.clientSocialNetworks.facebook ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newGoogleConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.googleMyBusiness
                        ? { color: '#4284F5' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      const modalElement = googleModalRef.current;
                      const bootstrapModal = new window.bootstrap.Modal(
                        modalElement
                      );
                      bootstrapModal.show();
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-google" />
                    {row.clientSocialNetworks.gmbFailing &&
                    row.clientSocialNetworks.googleMyBusiness ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newInstagramConnection"
                    className="dropdown-link new-indicator "
                    style={
                      row.clientSocialNetworks.instagram
                        ? { color: '#8a3ab9' }
                        : { color: '#8392a5' }
                    }
                    data-bs-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      const modalElement = instagramModalRef.current;
                      const bootstrapModal = new window.bootstrap.Modal(
                        modalElement
                      );
                      bootstrapModal.show();
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-instagram" />
                    {row.clientSocialNetworks.igFailing &&
                    row.clientSocialNetworks.instagram ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <a
                  href="#newZohoConnection"
                  className="dropdown-link new-indicator "
                  style={{
                    marginLeft: '5px',
                    marginTop: '3px'
                  }}
                  data-toggle="modal"
                  data-animation="effect-scale"
                  onClick={() => {
                    const modalElement = zohoModalRef.current;
                    const bootstrapModal = new window.bootstrap.Modal(
                      modalElement
                    );
                    bootstrapModal.show();
                    setCurrentClientId(row.id);
                  }}
                  data-backdrop="static"
                  data-keyboard="false">
                  <button
                    className=" button-like-a"
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => setCurrentClientId(row.id)}>
                    {renderZohoIcon(row.clientSocialNetworks?.zoho)}
                  </button>
                </a>
              </>
            ) : (
              <p style={{ color: 'red' }}>
                Error trying to load social networks, please contact support
              </p>
            )}
          </nav>
        </>
      );
    }
  };

  const clientName = (cell, row) => {
    if (row.id) {
      let newUrl = row.clientName
        .replace(/\s+/g, '%20')
        .replace(/\//g, '%2F')
        .replace(/'/g, '%1D')
        .replace(/’/g, '%1E');
      return (
        <div>
          <Link
            onClick={() => props.dispatch(setSelectedClient(row))}
            onMouseDown={() => props.dispatch(setSelectedClient(row))}
            to={{
              pathname: `/client/${newUrl}`,
              state: {
                clientId: row.id,
                clientGroups: row.clientGroups,
                socialNetworks: row['clientSocialNetworks']
              }
            }}>
            <span>{row['clientName']}</span>
          </Link>
        </div>
      );
    }
  };

  const renderAppIcon = (active) => {
    let iconStyle = {
      width: 30,
      height: 30,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/sms.png`
          : `${process.env.PUBLIC_URL}/assets/img/smsf.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const mobileApp = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            <button
              className=" button-like-a"
              style={{ marginRight: 10 }}
              onClick={() => setAppRegister({ show: true, data: row })}>
              {renderAppIcon(row.appActive)}
            </button>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                position: 'relative',
                color: '#8493a5',
                textAlign: 'center',
                marginLeft: 6
              }}>
              <p>{typeof row.smsCount === 'undefined' ? '0' : row.smsCount}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center',
                marginLeft: 6
              }}>
              <p>{row.yelpInfo ? row.yelpInfo.clicks : 0}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center',
                marginLeft: 6
              }}>
              <p>{row.gmbInfo ? row.gmbInfo.clicks : 0}</p>
            </div>
          </nav>
        </>
      );
    }
  };
  // Update table only if differences un clients list

  // Fetch all clients to listen for changes
  const undercoverGetClients = async () => {
    dispatch(showLoading());
    setLoading(true);
    try {
      await props.dispatch(getClients());
      dispatch(hideLoading());

      setLoading(false);
    } catch (error) {
      dispatch(hideLoading());

      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    undercoverGetClients();
    // eslint-disable-next-line
  }, []);

  const myCallback = (dataFromChild) => {
    undercoverGetClients();
  };

  const clientColumns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'clientName',
      text: 'Client Name',
      headerStyle: () => {
        return {
          width: '281px',
          textAlign: 'left',
          marginLeft: '15px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left',
          marginLeft: '10px'
        };
      },
      formatter: clientName
    },
    {
      dataField: 'accountOwnerFullName',
      text: 'Account Manager',
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'left',
          marginLeft: '15px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left',
          marginLeft: '10px'
        };
      },
      formatter: accountManagersColumn
    },
    {
      dataField: 'accountStatus',
      text: 'Status',
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'center',
          marginLeft: '15px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center',
          marginLeft: '10px'
        };
      },
      formatter: accountStatusColumn
    },
    {
      dataField: 'dummy-sn',
      text: 'Social Networks',
      formatter: clientSocialNetworks,
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'appActive',
      text: 'App - #SMS  Yelp  GMB',
      headerStyle: () => {
        return { width: '160px', textAlign: 'center' };
      },
      formatter: mobileApp
    },
    {
      dataField: 'dummy-actions',
      text: 'Actions',
      formatter: clientActions,
      headerStyle: () => {
        return {
          width: '150px',
          textAlign: 'center',
          alignItems: 'center'
        };
      }
    }
  ];

  const connectSnCallback = () => {
    myCallback();
  };

  const dataClientHandler = (
    clientId,
    socialnetworks,
    timeZone,
    bestTimes,
    mergeFields
  ) => {
    setCurrentClientId(clientId);
    setCurrentClientSocialNetworks(socialnetworks);
    setCurrentClientTimeZone(timeZone);
    setCurrentClientBestTimes(bestTimes);
    setCurrentClientMergeFields(mergeFields);
    let validSn = validNetworks(socialnetworks);
    if (!validSn) {
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'Sync Any Social Network.'
      });
    }
  };
  const userRole = props.role;

  return (
    <>
      <div className="content ht-100v pd-0 datatable-content-body">
        <DataTables
          buttonsCallback={connectSnCallback}
          userRole={userRole}
          data={props.customerList}
          columns={clientColumns}
          noDataIndication={'No Clients'}
          handleModal={handleShow}
          showTotal={true}
          title={'Clients'}
        />
      </div>
      <NewFacebookPageConnection
        ref={facebookModalRef}
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewZohoConnection
        ref={zohoModalRef}
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewGoogleConnection
        ref={googleModalRef}
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewInstagramConnection
        ref={instagramModalRef}
        currentClientId={currentClientId}
        currentClient={currentClient}
        instagramPages={instagramPages != null}
        connectSnCallback={connectSnCallback}
      />
      {newPost.show && (
        <NewPost
          show={newPost.show}
          currentClientId={currentClientId}
          currentClientSocialNetworks={currentClientSocialNetworks}
          currentClientTimeZone={currentClientTimeZone}
          currentClientBestTimes={currentClientBestTimes}
          currentClientMergeFields={currentClientMergeFields}
          currentCustomerName={props.customerName}
          callBackClose={callBackClose}
        />
      )}
      {appRegister.show && (
        <AppRegister
          data={appRegister.data}
          onUpdate={() => {
            myCallback();
          }}
          handleClose={() => setAppRegister({ appRegister: { show: false } })}
        />
      )}
      <BulkModal
        title={'posts'}
        currentClientId={currentClientId}
        currentClientSocialNetworks={currentClientSocialNetworks}
        currentClientTimeZone={currentClientTimeZone}
        currentCustomerName={props.customerName}
      />
    </>
  );
};

function mapStateToProps(state) {
  let { errorLogin, loading, userInfo } = state.auth;
  let { loadingClients, errorClients, customerList } = state.clients;
  return {
    customerName: userInfo?.displayName,
    customerEmail: userInfo?.email,
    customerPhoto: userInfo?.photoURL,
    role: userInfo?.role,
    isLoggingOut: loading,
    logoutError: errorLogin,
    loadingClients,
    errorClients,
    customerList
  };
}
export default connect(mapStateToProps)(Dashboard);
