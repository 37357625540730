import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ModalInfo = ({ logInfo = {}, handleClose = () => {} }) => {
  const extraerEnlaceImagen = (texto) => {
    const regex =
      /<a target=\"_blank\" href=\"(https?.+\.(?:jpg|jpeg|png|gif))\">/i;
    const coincidencia = texto.match(regex);
    return coincidencia ? coincidencia[1] : null;
  };

  function extractTextBetweenBrackets(inputString) {
    const regex = /\[([^\]]+)\]/;
    const match = inputString.match(regex);

    if (match) {
      return match[1];
    } else {
      return null; // O puedes retornar un mensaje indicando que no se encontró coincidencia
    }
  }

  function convertTimeArray(inputArray) {
    if (inputArray == undefined) {
      return [];
    }

    const resultArray = [];
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    const periods = {};

    inputArray.forEach((item) => {
      const periodMatch = item.key.match(
        /regularHours\.periods\[(\d+)\]\.closeTime\.(hours|minutes)/
      );
      if (periodMatch) {
        const periodIndex = periodMatch[1];
        const timePart = periodMatch[2];

        if (!periods[periodIndex]) {
          periods[periodIndex] = {};
        }

        periods[periodIndex][timePart] = item;
      }
    });

    Object.keys(periods).forEach((index) => {
      const hours = periods[index].hours;
      const minutes =
        periods[index]?.minutes?.value1 != undefined
          ? periods[index].minutes.value1
          : 0;
      const day = daysOfWeek[index];
      const time1 = `${day} ${hours.value1}:${
        minutes < 10 ? '0' + minutes : minutes
      }`;
      const time2 = hours.value2 ? `${day} ${hours.value2}:00` : undefined;

      const newItem = {
        key: hours.key,
        value1: time1,
        value2: time2
      };

      resultArray.push(newItem);
    });

    return resultArray;
  }

  function convertTimeArray2(inputArray) {
    if (inputArray == undefined) {
      return [];
    }

    const resultArray = [];
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    const periods = {};

    inputArray.forEach((item) => {
      const periodMatch = item.key.match(
        /specialHours\.specialHourPeriods\[\d+\]\.openTime\.(hours|minutes)/
      );
      if (periodMatch) {
        const periodIndex = periodMatch[1];
        const timePart = periodMatch[2];

        if (!periods[periodIndex]) {
          periods[periodIndex] = {};
        }

        periods[periodIndex][timePart] = item;
      }
    });

    Object.keys(periods).forEach((index) => {
      const hours = periods[index].hours;
      const minutes =
        periods[index]?.minutes?.value1 != undefined
          ? periods[index].minutes.value1
          : 0;
      const day = daysOfWeek[index];
      const time1 = `${day} ${hours.value1}:${
        minutes < 10 ? '0' + minutes : minutes
      }`;
      const time2 = hours.value2 ? `${day} ${hours.value2}:00` : undefined;

      const newItem = {
        key: hours.key,
        value1: time1,
        value2: time2
      };

      resultArray.push(newItem);
    });

    return resultArray;
  }

  const enlaceImagen = extraerEnlaceImagen(logInfo.title);

  const serviceTypesRegex =
    /categories\.primaryCategory\.serviceTypes\.displayName/;
  const serviceTypesItems = logInfo.msg_content?.filter((item) =>
    serviceTypesRegex.test(item.key)
  );
  const beforeServiceTypes = serviceTypesItems
    ?.map((item) => item.value1)
    .join('<br/>');
  const afterServiceTypes = serviceTypesItems
    ?.map((item) => item.value2)
    .join('<br/>');
  const displayBeforeServiceTypes = beforeServiceTypes
    ? beforeServiceTypes
    : '';
  const displayAfterServiceTypes = afterServiceTypes ? afterServiceTypes : '';

  const ServiceItemsRegex = /serviceItems\.displayName/;
  const ServiceItemsItems = logInfo.msg_content?.filter((item) =>
    ServiceItemsRegex.test(item.key)
  );
  const beforeServiceItems = ServiceItemsItems?.map((item) => item.value1).join(
    '<br/>'
  );
  const afterServiceItems = ServiceItemsItems?.map((item) => item.value2).join(
    '<br/>'
  );
  const displayBeforeServiceItems = beforeServiceItems
    ? beforeServiceItems
    : '';
  const displayAfterServiceItems = afterServiceItems ? afterServiceItems : '';

  const daysRegex = /regularHours\.periods\[\d+\]\.openDay/;
  const daysItems = logInfo.msg_content?.filter((item) =>
    daysRegex.test(item.key)
  );
  const beforeDaysTypes = daysItems?.map((item) => item.value1).join('<br/>');
  const afterDaysTypes = daysItems?.map((item) => item.value2).join('<br/>');
  const displayBeforeDays = beforeDaysTypes ? beforeDaysTypes : '';
  const displayAfterDays = afterDaysTypes ? afterDaysTypes : '';

  const closeTimeRegex = /regularHours\.periods\[\d+\]\.closeTime/;
  let closeTimeItems = logInfo.msg_content?.filter((item) =>
    closeTimeRegex.test(item.key)
  );
  closeTimeItems = convertTimeArray(closeTimeItems);
  const beforeCloseTimeTypes = closeTimeItems
    ?.map((item) => item.value1)
    .join('<br/>');
  const afterCloseTimeTypes = closeTimeItems
    ?.map((item) => item.value2)
    .join('<br/>');
  const displayBeforeCloseTime = beforeCloseTimeTypes
    ? beforeCloseTimeTypes
    : '';
  const displayAfterCloseTime = afterCloseTimeTypes ? afterCloseTimeTypes : '';

  const openTimeRegex = /regularHours\.periods\[[A-Za-z]+\]\.openTime/;
  let openTimeItems = logInfo.msg_content?.filter((item) =>
    openTimeRegex.test(item.key)
  );

  const beforeOpenTimeTypes = openTimeItems
    ?.map((item) => `${extractTextBetweenBrackets(item.key)} ${item.value1}`)
    .join('<br/>');
  const afterOpenTimeTypes = openTimeItems
    ?.map((item) => `${extractTextBetweenBrackets(item.key)} ${item.value2}`)
    .join('<br/>');
  const displayBeforeOpenTime = beforeOpenTimeTypes ? beforeOpenTimeTypes : '';
  const displayAfterOpenTime = afterOpenTimeTypes ? afterOpenTimeTypes : '';

  const specialHourTimeRegex =
    /specialHours\.specialHourPeriods\[\d+\]\.openTime/;
  let specialHourTimeItems = logInfo.msg_content?.filter((item) =>
    specialHourTimeRegex.test(item.key)
  );
  specialHourTimeItems = convertTimeArray2(specialHourTimeItems);

  const beforeSpecialHourTimeTypes = specialHourTimeItems
    ?.map((item) => item.value1)
    .join('<br/>');
  const afterSpecialHourTimeTypes = specialHourTimeItems
    ?.map((item) => item.value2)
    .join('<br/>');
  const displayBeforeSpecialHourTime = beforeSpecialHourTimeTypes
    ? beforeSpecialHourTimeTypes
    : '';
  const displayAfterSpecialHourTime = afterSpecialHourTimeTypes
    ? afterSpecialHourTimeTypes
    : '';

  const specialHourTimeRegex_c =
    /specialHours\.specialHourPeriods\[\d+\]\.closeTime/;
  let specialHourTimeItems_c = logInfo.msg_content?.filter((item) =>
    specialHourTimeRegex_c.test(item.key)
  );
  specialHourTimeItems_c = convertTimeArray2(specialHourTimeItems_c);

  const beforeSpecialHourTimeTypes_c = specialHourTimeItems_c
    ?.map((item) => item.value1)
    .join('<br/>');
  const afterSpecialHourTimeTypes_c = specialHourTimeItems_c
    ?.map((item) => item.value2)
    .join('<br/>');
  const displayBeforeSpecialHourTime_c = beforeSpecialHourTimeTypes_c
    ? beforeSpecialHourTimeTypes
    : '';
  const displayAfterSpecialHourTime_c = afterSpecialHourTimeTypes_c
    ? afterSpecialHourTimeTypes
    : '';

  const serviceAreaRegex = /serviceArea\.places\.placeInfos\.displayName/;
  const serviceAreaItems = logInfo.msg_content?.filter((item) =>
    serviceAreaRegex.test(item.key)
  );
  const beforeserviceAreaTypes = serviceAreaItems
    ?.map((item) => item.value1)
    .join('<br/>');
  const afterserviceAreaTypes = serviceAreaItems
    ?.map((item) => item.value2)
    .join('<br/>');
  const displayBeforeserviceArea = beforeserviceAreaTypes
    ? beforeserviceAreaTypes
    : '';
  const displayAfterserviceArea = afterserviceAreaTypes
    ? afterserviceAreaTypes
    : '';

  return (
    <Modal
      id="viewLog"
      backdrop="static"
      keyboard={false}
      show={true}
      onHide={() => {}}>
      <Modal.Header
        style={{
          backgroundColor: '#3B5998'
        }}>
        <h5
          style={{ color: 'white' }}
          className="modal-title"
          id="addNewClientLabel">
          {logInfo.name || '--'}
        </h5>
        <button
          onClick={() => handleClose()}
          style={{
            backgroundColor: '#3B5998',
            color: 'white',
            border: 'none'
          }}
          className="close"
          type="button">
          <span style={{ color: 'white' }} aria-hidden="true">
            ×
          </span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div
          className="modal-body"
          style={{
            maxHeight: '800px', // Altura del div
            overflow: 'auto'
          }}>
          <div className="row row-sm">
            <div className="col-sm-6">
              <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                Date & Time
              </label>
              <p className="event-start-date" style={{ color: '#8392A5' }}>
                {logInfo.createdAt
                  ? `${new Date(logInfo.createdAt).toLocaleDateString()}`
                  : '--'}
              </p>
              <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                Module
              </label>
              <p>{logInfo.type || '--'}</p>
            </div>

            <div id="timestatus" className="col-sm-6">
              <div>
                <>
                  <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
                    Level
                  </label>
                  <br />
                  <p style={{ color: '#3B5998' }}>{logInfo.level || 'INFO'}</p>
                </>
              </div>
            </div>
          </div>

          <br />
          <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
            Message Text
          </label>
          <p dangerouslySetInnerHTML={{ __html: logInfo.title }} />

          {enlaceImagen ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <img
                src={enlaceImagen}
                alt="no image"
                style={{ maxWidth: '80%' }}
              />
            </div>
          ) : (
            <p></p>
          )}

          {logInfo.msg_content ? (
            <table class="table table-bordered">
              <tbody>
                {logInfo.msg_content.map((item, index) => {
                  if (
                    item.key === 'title' ||
                    item.key === 'categories.primaryCategory.displayName' ||
                    item.key === 'profile.description' ||
                    item.key === 'websiteUri' ||
                    item.key === 'metadata.mapsUri' ||
                    item.key === 'phoneNumbers.primaryPhone' ||
                    item.key === 'openInfo.openingDate.year' ||
                    item.key === 'openInfo.openingDate.day' ||
                    item.key === 'openInfo.openingDate.month' ||
                    item.key.startsWith('latlng')
                  ) {
                    return (
                      <>
                        <tr key={index}>
                          <td colSpan="2" style={{ fontWeight: 'bold' }}>
                            {item.key}
                          </td>
                        </tr>
                        <tr key={item.value1}>
                          <td
                            style={{
                              overflow: 'auto',
                              color: '#840000'
                            }}>
                            {item.value1}
                          </td>
                          <td style={{ color: '#006147' }}>{item.value2}</td>
                        </tr>
                      </>
                    );
                  }

                  if (item.key === 'storefrontAddress') {
                    return (
                      <>
                        <tr key={index}>
                          <td colSpan="2" style={{ fontWeight: 'bold' }}>
                            {item.key}
                          </td>
                        </tr>
                        <tr key={item.value1}>
                          <td style={{ color: '#840000' }}>
                            {item?.value1?.addressLines !== undefined
                              ? item.value1.addressLines
                              : 'none'}
                          </td>
                          <td style={{ color: '#006147' }}>
                            {item?.value2?.addressLines !== undefined
                              ? item.value2.addressLines
                              : 'none'}
                          </td>
                        </tr>
                      </>
                    );
                  }

                  if (item.key === 'serviceArea.businessType') {
                    return (
                      <>
                        <tr key={index}>
                          <td colSpan="2" style={{ fontWeight: 'bold' }}>
                            {item.key}
                          </td>
                        </tr>
                        <tr key={item.value1}>
                          <td style={{ color: '#840000' }}>{item.value1}</td>
                          <td style={{ color: '#006147' }}>{item.value2}</td>
                        </tr>
                      </>
                    );
                  }

                  if (item.key === 'serviceItems') {
                    return (
                      <>
                        <tr key={index}>
                          <td colSpan="2" style={{ fontWeight: 'bold' }}>
                            Services
                          </td>
                        </tr>
                        <tr key={index}>
                          <td style={{ color: '#840000' }}>
                            {Array.isArray(item.value1)
                              ? item.value1.map((val, index) => (
                                  <>
                                    <span
                                      key={index}
                                      style={{ display: 'block' }}>
                                      {
                                        val?.freeFormServiceItem?.label
                                          ?.displayName
                                      }
                                    </span>
                                  </>
                                ))
                              : 'N/A'}
                          </td>
                          <td style={{ color: '#006147' }}>
                            {Array.isArray(item.value2)
                              ? item.value2.map((val, index) => (
                                  <>
                                    <span
                                      key={index}
                                      style={{ display: 'block' }}>
                                      {
                                        val?.freeFormServiceItem?.label
                                          ?.displayName
                                      }
                                    </span>
                                  </>
                                ))
                              : 'N/A'}
                          </td>
                        </tr>
                      </>
                    );
                  }
                })}

                {serviceAreaItems.length > 0 ? (
                  <>
                    <tr key="6">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Service Areas
                      </td>
                    </tr>
                    <tr key="7">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeserviceArea
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterserviceArea
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {serviceTypesItems.length > 0 ? (
                  <>
                    <tr key="8">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Services Types
                      </td>
                    </tr>
                    <tr key="9">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeServiceTypes
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterServiceTypes
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {ServiceItemsItems.length > 0 ? (
                  <>
                    <tr key="8">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Services Items
                      </td>
                    </tr>
                    <tr key="9">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeServiceItems
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterServiceItems
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {daysItems.length > 0 ? (
                  <>
                    <tr key="10">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Days
                      </td>
                    </tr>
                    <tr key="11">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{ __html: displayBeforeDays }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{ __html: displayAfterDays }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {closeTimeItems.length > 0 ? (
                  <>
                    <tr key="12">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Close time
                      </td>
                    </tr>
                    <tr key="13">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeCloseTime
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterCloseTime
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {openTimeItems.length > 0 ? (
                  <>
                    <tr key="14">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Open Times
                      </td>
                    </tr>
                    <tr key="15">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeOpenTime
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterOpenTime
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {specialHourTimeItems.length > 0 ? (
                  <>
                    <tr key="16">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Special Hours
                      </td>
                    </tr>
                    <tr key="17">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeSpecialHourTime
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterSpecialHourTime
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}

                {specialHourTimeItems_c.length > 0 ? (
                  <>
                    <tr key="16">
                      <td colSpan="2" style={{ fontWeight: 'bold' }}>
                        Soecial Hours
                      </td>
                    </tr>
                    <tr key="17">
                      <td
                        style={{ color: '#840000' }}
                        dangerouslySetInnerHTML={{
                          __html: displayBeforeSpecialHourTime_c
                        }}
                      />
                      <td
                        style={{ color: '#006147' }}
                        dangerouslySetInnerHTML={{
                          __html: displayAfterSpecialHourTime_c
                        }}
                      />
                    </tr>
                  </>
                ) : (
                  ''
                )}
              </tbody>
            </table>
          ) : (
            ''
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
          }}>
          <button
            className={'btn btn-secondary pd-x-20'}
            disabled={false}
            data-dismiss="modal"
            ref={(button) => {
              //   setModalDismiss(button);
            }}
            onClick={() => {
              handleClose();
            }}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalInfo;
