import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import InfoField from './InfoField.js';
import PhoneField from './PhoneField.js';
import OpeningDateField from './OpeningDateField.js';
import Categories from './Categories.js';
import Hours from './Hours.js';
import ServiceArea from './ServiceArea.js';
import { getGoogleProfileInfo } from './services/index';
import { selectedClientSelector } from '../../store/slices/selectedClient';
import {
  API_UPDATE_GOOGLE_PROFILE_INFO,
  API_GET_CATEGORIES
} from '../../../src/utils';
import SpecialHours from './SpecialHours';
import './styles.css';

const GoogleBusinessProfile = ({ isEditing, setIsEditing }) => {
  const [googleProfileInfo, setGoogleProfileInfo] = useState({
    loading: true,
    data: {}
  });
  const [currSection, setCurrSection] = useState('about');
  const [editedFields, setEditedFields] = useState({});
  const [categories, setCategories] = useState([]);
  const [gettingCategories, setGettingCategories] = useState(false);
  const [hours, setHours] = useState([]);

  const getCategories = async () => {
    setGettingCategories(true);
    const equalsCheck = (a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    };
    try {
      await axios.get(`${API_GET_CATEGORIES}`).then((response) => {
        const { categories: cs } = response.data;
        if (
          !equalsCheck(
            categories,
            cs.map((category) => ({
              ...category,
              value: category.id,
              label: category.display_name
            }))
          ) ||
          googleProfileInfo.data?.categories.primaryCategory.displayName === ''
        ) {
          setCategories(
            cs.map((category) => ({
              ...category,
              value: category.id,
              label: category.display_name
            }))
          );
        }
        setGettingCategories(false);
      });
    } catch (e) {
      console.error('An error has ocurred retrieving the google categories', e);
      setGettingCategories(false);
      return [];
    }
    setGettingCategories(false);
  };

  useEffect(() => {
    if (categories.length === 0 && !gettingCategories) {
      getCategories();
    }
  }, []);

  useEffect(() => {
    if (googleProfileInfo.data?.regularHours?.periods) {
      const completeHours = (periods) => {
        const defaultOpenTime = { hours: 8 };
        const defaultCloseTime = { hours: 17 };
        const defaultDayOrder = [
          'MONDAY',
          'TUESDAY',
          'WEDNESDAY',
          'THURSDAY',
          'FRIDAY',
          'SATURDAY',
          'SUNDAY'
        ];

        return periods.map((period, index) => {
          return (
            period || {
              openDay: defaultDayOrder[index],
              openTime: defaultOpenTime,
              closeDay: defaultDayOrder[index],
              closeTime: defaultCloseTime
            }
          );
        });
      };

      const completedHours = completeHours(
        googleProfileInfo.data.regularHours.periods
      );

      if (JSON.stringify(completedHours) !== JSON.stringify(hours)) {
        setHours(completedHours);
      }
    }
  }, []);

  const [GBPfields] = useState({
    businessName: false,
    businessCategory: false,
    description: false,
    openingDate: false,
    phoneNumber: false,
    website: false,
    socialProfiles: true,
    shortName: true,
    businessLocation: true, // to review
    serviceArea: true, // to review
    hours: true, // to review
    specialHours: true,
    moreHours: true,
    fromTheBusiness: true,
    crowd: true,
    planning: true,
    serviceOptions: true
  });

  const handleCancel = () => {
    setIsEditing(false);
    setEditedFields({});
  };

  const handleSave = () => {
    setIsEditing(false);

    const editedData = {
      clientId: selectedClient?.id,
      name: googleProfileInfo?.data?.name,
      languageCode: googleProfileInfo?.data?.languageCode,
      title: editedFields?.businessName,
      phoneNumbers: googleProfileInfo?.data?.phoneNumbers,
      categories: editedFields?.businessCategory,
      websiteUri: editedFields?.website,
      regularHours: googleProfileInfo?.data?.regularHours,
      serviceArea: googleProfileInfo?.data?.serviceArea,
      // openInfo: {
      //   status: "OPEN",
      //   canReopen: true,
      //   openingDate: editedFields?.openingDate
      // },
      openInfo: googleProfileInfo?.data?.openInfo,
      metadata: googleProfileInfo?.data?.metadata,
      profile: {
        description: editedFields?.description
      }
    };

    const headers = {
      'Content-Type': 'application/json'
    };

    axios
      .put(API_UPDATE_GOOGLE_PROFILE_INFO, editedData, { headers })
      .then((response) => {
        console.log('PUT request response:', response.data);
      })
      .catch((error) => {
        console.error('An error has ocurred saving the data:', error);
      });
  };

  const handleFieldChange = (fieldName, value) => {
    setEditedFields((prevEditedFields) => {
      if (prevEditedFields[fieldName] === value) {
        return prevEditedFields;
      }
      return {
        ...prevEditedFields,
        [fieldName]: value
      };
    });
  };

  const { selectedClient } = useSelector(selectedClientSelector);

  useEffect(() => {
    const card = document.getElementById('scrollCard');
    if (card) {
      const handleScroll = () => {
        const sections = card.querySelectorAll('div[id]');
        let newSectionId = '';

        sections.forEach((section) => {
          const rect = section.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= card.clientHeight) {
            newSectionId = section.id;
          }
        });

        if (newSectionId && newSectionId !== currSection) {
          setCurrSection(newSectionId);
        }
      };

      card.addEventListener('scroll', handleScroll);
      return () => {
        card.removeEventListener('scroll', handleScroll);
      };
    }
  }, [currSection]);

  useEffect(() => {
    const loadGoogleProfile = async () => {
      setGoogleProfileInfo({ loading: true, data: null });
      if (selectedClient?.id) {
        try {
          const profileInfo = await getGoogleProfileInfo(selectedClient.id);
          setGoogleProfileInfo(profileInfo);
        } catch (error) {
          console.error('Error loading Google profile info:', error);
          setGoogleProfileInfo({ loading: false, data: null });
        }
      } else {
        setGoogleProfileInfo({ loading: false, data: null });
      }
    };

    loadGoogleProfile();

    return () => {
      setGoogleProfileInfo({}); // Limpiar estado si es necesario
    };
  }, [selectedClient?.id]);

  return (
    <div style={{ position: 'inherit' }}>
      {googleProfileInfo.loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-border-sm">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '620px',
            paddingTop: '20px'
          }}>
          <nav
            className="nav nav-pills"
            style={{
              boxSizing: 'border-box',
              paddingLeft: '10px',
              backgroundColor: 'white'
            }}>
            <a
              href="#about"
              onClick={() => setCurrSection('about')}
              className={`nav-link ${currSection === 'about' ? 'active' : ''}`}>
              About
            </a>
            <a
              href="#contact"
              onClick={() => setCurrSection('contact')}
              className={`nav-link ${
                currSection === 'contact' ? 'active' : ''
              }`}>
              Contact
            </a>
            <a
              href="#location"
              onClick={() => setCurrSection('location')}
              className={`nav-link ${
                currSection === 'location' ? 'active' : ''
              }`}>
              Location
            </a>
            <a
              href="#hours"
              onClick={() => setCurrSection('hours')}
              className={`nav-link ${currSection === 'hours' ? 'active' : ''}`}>
              Hours
            </a>
            <a
              href="#more"
              onClick={() => setCurrSection('more')}
              className={`nav-link ${currSection === 'more' ? 'active' : ''}`}>
              More
            </a>
          </nav>
          <div
            id="scrollCard"
            style={{
              marginTop: '10px',
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: '500px'
            }}>
            <div id="about" className="pd-25" style={{ minHeight: '300px' }}>
              <div className="title">
                <div>
                  <h5 style={{ marginBottom: '0.9rem' }}>
                    About your business
                  </h5>
                </div>
              </div>
              <InfoField
                title={'Business name'}
                isEditing={isEditing}
                canEdit={!GBPfields?.businessName}
                onFieldChange={(value) =>
                  handleFieldChange('businessName', value)
                }>
                {googleProfileInfo.data?.title ? (
                  googleProfileInfo.data?.title
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <Categories
                title={'Business category'}
                isEditing={isEditing}
                canEdit={!GBPfields.businessCategory}
                categories={categories}
                googleProfileInfo={
                  googleProfileInfo.data?.categories?.primaryCategory
                    ?.displayName
                }
                onFieldChange={(value) =>
                  handleFieldChange('businessCategory', value)
                }>
                {googleProfileInfo.data?.categories?.primaryCategory
                  ?.displayName ? (
                  googleProfileInfo.data?.categories.primaryCategory.displayName
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </Categories>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Description'}
                isEditing={isEditing}
                canEdit={!GBPfields.description}
                onFieldChange={(value) =>
                  handleFieldChange('description', value)
                }>
                {googleProfileInfo.data?.profile?.description ? (
                  googleProfileInfo.data?.profile?.description
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <OpeningDateField
                title={'Opening date'}
                isEditing={isEditing}
                canEdit={!GBPfields.openingDate}
                openingDate={googleProfileInfo.data?.openInfo?.openingDate}
                onFieldChange={(value) =>
                  handleFieldChange('openingDate', value)
                }>
                {googleProfileInfo.data?.openInfo?.openingDate ? (
                  googleProfileInfo.data?.openInfo?.openingDate
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </OpeningDateField>
            </div>
            <div id="contact" className="pd-25" style={{ minHeight: '300px' }}>
              <div className="title">
                <div>
                  <h5 style={{ marginBottom: '0.9rem' }}>
                    Contact Information
                  </h5>
                </div>
              </div>
              <PhoneField
                title={'Phone number'}
                isEditing={isEditing}
                canEdit={!GBPfields.phoneNumber}
                googleProfileInfo={googleProfileInfo.data?.phoneNumbers}
                onFieldChange={(value) =>
                  handleFieldChange('phoneNumber', value)
                }>
                {googleProfileInfo.data?.phoneNumbers ? (
                  <div>
                    {googleProfileInfo.data?.phoneNumbers?.primaryPhone}{' '}
                    <span className="badge badge-secondary">PRIMARY</span>
                    <br />
                    {googleProfileInfo.data?.phoneNumbers?.additionalPhones?.map(
                      (phone, index) => {
                        return (
                          <span key={index}>
                            {phone}
                            <br />
                          </span>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </PhoneField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Website'}
                isEditing={isEditing}
                canEdit={!GBPfields.website}
                onFieldChange={(value) => handleFieldChange('website', value)}>
                {googleProfileInfo.data?.websiteUri ? (
                  googleProfileInfo.data?.websiteUri
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={
                  <>
                    Social profiles{' '}
                    <span className="badge badge-secondary">NEW</span>
                  </>
                }
                isEditing={isEditing}
                canEdit={!GBPfields.socialProfiles}
                onFieldChange={(value) =>
                  handleFieldChange('socialProfiles', value)
                }>
                {googleProfileInfo.data?.description ? (
                  googleProfileInfo.data?.description
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Short name'}
                isEditing={isEditing}
                canEdit={!GBPfields.shortName}
                onFieldChange={(value) =>
                  handleFieldChange('shortName', value)
                }>
                {googleProfileInfo.data?.title ? (
                  googleProfileInfo.data?.title.toLowerCase()
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
            </div>
            <div id="location" className="pd-25" style={{ minHeight: '300px' }}>
              <div className="title">
                <div>
                  <h5 style={{ marginBottom: '0.9rem' }}>Location and areas</h5>
                </div>
              </div>
              <InfoField
                title={'Business location'}
                isEditing={isEditing}
                canEdit={!GBPfields.businessLocation}
                onFieldChange={(value) =>
                  handleFieldChange('businessLocation', value)
                }>
                {googleProfileInfo.data?.businessName ? (
                  googleProfileInfo.data?.businessName
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <ServiceArea
                title={'Service area'}
                isEditing={isEditing}
                canEdit={!GBPfields.serviceArea}
                googleProfileInfo={
                  googleProfileInfo.data?.serviceArea?.places?.placeInfos
                }
                onFieldChange={(value) =>
                  handleFieldChange('serviceArea', value)
                }>
                {googleProfileInfo.data?.serviceArea?.places?.placeInfos ? (
                  googleProfileInfo.data?.serviceArea?.places?.placeInfos.map(
                    (place, index) => {
                      return (
                        <span key={index}>
                          {place.placeName}
                          <br />
                        </span>
                      );
                    }
                  )
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </ServiceArea>
            </div>
            <div id="hours" className="pd-25" style={{ minHeight: '300px' }}>
              <div className="title">
                <div>
                  <h5 style={{ marginBottom: '0.9rem' }}>Opening hours</h5>
                </div>
              </div>
              {googleProfileInfo.data?.regularHours?.periods && (
                <Hours
                  title={'Hours'}
                  isEditing={isEditing}
                  canEdit={!GBPfields.hours}
                  googleProfileInfo={googleProfileInfo.data?.regularHours}
                  // googleProfileInfo={hours}
                  onFieldChange={(value) => handleFieldChange('hours', value)}>
                  <style>
                    {`.opening-hours {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                  }
                  .opening-hours span {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                  }
                  .opening-hours .day {
                    flex: 1;
                    text-align: left;
                  }
                  .opening-hours .time {
                    flex: 1;
                    text-align: right;
                  }`}
                  </style>
                  {googleProfileInfo.data?.regularHours?.periods ? (
                    <div className="opening-hours">
                      {googleProfileInfo.data?.regularHours?.periods.map(
                        (period, index) => (
                          <span key={index}>
                            <span className="day">
                              {period.openDay.charAt(0).toUpperCase() +
                                period.openDay.toLowerCase().slice(1)}
                            </span>
                            <span className="time">
                              {period.openTime.hours}:00 -{' '}
                              {period.closeTime.hours}:00
                            </span>
                          </span>
                        )
                      )}
                    </div>
                  ) : (
                    <i className="fas fa-minus"></i>
                  )}
                </Hours>
              )}
              <Divider orientation="horizontal" />
              <InfoField
                title={'Special hours'}
                isEditing={isEditing}
                canEdit={!GBPfields.specialHours}
                onFieldChange={(value) =>
                  handleFieldChange('specialHours', value)
                }>
                {googleProfileInfo.data?.specialHours ? (
                  <SpecialHours
                    specialHourPeriods={
                      googleProfileInfo.data.specialHours.specialHourPeriods
                    }
                  />
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'More hours'}
                isEditing={isEditing}
                canEdit={!GBPfields.moreHours}
                onFieldChange={(value) =>
                  handleFieldChange('moreHours', value)
                }>
                {googleProfileInfo.data?.moreHours ? (
                  googleProfileInfo.data?.moreHours
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
            </div>
            <div id="more" className="pd-25" style={{ minHeight: '300px' }}>
              <div className="title">
                <div>
                  <h5 style={{ marginBottom: '0.9rem' }}>More</h5>
                </div>
              </div>
              <InfoField
                title={'From the business'}
                isEditing={isEditing}
                canEdit={!GBPfields.fromTheBusiness}
                onFieldChange={(value) =>
                  handleFieldChange('fromTheBusiness', value)
                }>
                {googleProfileInfo.data?.frombusiness ? (
                  googleProfileInfo.data?.frombusiness
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Crowd'}
                isEditing={isEditing}
                canEdit={!GBPfields.crowd}
                onFieldChange={(value) => handleFieldChange('crowd', value)}>
                {googleProfileInfo.data?.crowd ? (
                  googleProfileInfo.data?.crowd
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Planning'}
                isEditing={isEditing}
                canEdit={!GBPfields.planning}
                onFieldChange={(value) => handleFieldChange('planning', value)}>
                {googleProfileInfo.data?.planning ? (
                  googleProfileInfo.data?.planning
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
              <Divider orientation="horizontal" />
              <InfoField
                title={'Service options'}
                isEditing={isEditing}
                canEdit={!GBPfields.serviceOptions}
                onFieldChange={(value) =>
                  handleFieldChange('serviceOptions', value)
                }>
                {googleProfileInfo.data?.serviceOptions ? (
                  googleProfileInfo.data?.serviceOptions
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </InfoField>
            </div>
          </div>
          {isEditing && (
            <div className="buttons-container">
              <div className="button" style={{ paddingLeft: '25px' }}>
                <button className="btn btn-xs btn-white" onClick={handleSave}>
                  Save
                </button>
              </div>
              <div className="button" style={{ paddingLeft: '10px' }}>
                <button
                  className="btn btn-xs btn-primary"
                  onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

GoogleBusinessProfile.propTypes = {
  open: PropTypes.bool.isRequired
};

GoogleBusinessProfile.defaultProps = {
  open: true
};

export default GoogleBusinessProfile;
