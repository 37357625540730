import React, {
  createRef,
  useState,
  useEffect,
  useRef,
  forwardRef
} from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import ButtonLoad from '../ButtonLoad';
import RecurrentPosts from '../RecurrentPosts';
import Picker from 'emoji-picker-react';
import EditImgModal from '../EditImgModal';
import S3 from 'react-aws-s3';
import { v4 as uuidv4 } from 'uuid';
import sizeOf from 'image-size';
import http from 'http';
import url from 'url';
import { MANIPULATE_DESCRIPTION } from '../../utils';
import { authSelector } from '../../store/slices/auth';
import { useSelector } from 'react-redux';

import Clock from 'react-live-clock';
import { cloneDeep } from 'lodash';
import {
  API_POSTS,
  API_ASSETS,
  API_POSTS_EDIT,
  API_POSTS_REPAIR,
  AWS_KEY,
  AWS_SECRET,
  AWS_REGION,
  AWS_BUCKET,
  AWS_S3URL
} from '../../utils';
import { allowedEmails } from '../../utils';
import './NewPost.scss';
import IGTagsPost from '../IGTags/IGTagsPost';
import TabMenu from '../../pages/Calendar/GallerySection/MenuGallery/TabMenu';
import UserGallery from '../../pages/Calendar/GallerySection/UserGallery';
import GoogleGallery from '../../pages/Calendar/GallerySection/GoogleGallery';
import Unsplash from '../../pages/Calendar/GallerySection/StockImagesComponents/Unsplash';
import Pixabay from '../../pages/Calendar/GallerySection/StockImagesComponents/Pixabay';
import Pexels from '../../pages/Calendar/GallerySection/StockImagesComponents/Pexels';
import { SearchContext } from '../../contexts/SearchContext';
import ContentGuidelineTooltip from '../Notes/ContentGuidlineTooltip';
import ContentGallery from '../../pages/Calendar/GallerySection/ContentGallery';
import ClientGallery from '../../pages/Calendar/GallerySection/ClientGallery';
import OfferModal from './OfferPost/OfferModal';
const reactStringReplace = require('react-string-replace');
const axios = require('axios');

const NewPost = forwardRef((props, ref) => {
  const { isAuthenticated, userInfo } = useSelector(authSelector);
  const currentEmail = userInfo?.email;
  const currentRole = userInfo?.role;
  const [date, setDate] = useState(null);
  const [post, setPost] = useState({
    socialNetworks: {
      facebook: false,
      twitter: false,
      googleMyBusiness: false,
      instagram: false
    }
  });
  const [assetUrl, setAssetUrl] = useState({
    value: ''
  });

  const [savingPost, setSavingPost] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [repairingPost, setRepairingPost] = useState(false);
  const [preview, setPreview] = useState(null);
  const [showRecurrent, setShowRecurrent] = useState(false);
  const [checked, setChecked] = useState(false);
  const [recurrentDates, setRecurrentDates] = useState([]);
  const [isCustomTime, setIsCustomTime] = useState(false);
  const [postDescription, setPostDescription] = useState('');
  const [showEmojisMenu, setShowEmojisMenu] = useState(false);
  const [showImgEditModal, setShowImgEditModal] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);
  const [textAreaCursorPostion, setTextAreaCursorPostion] = useState({
    selectionStart: 0,
    selectionEnd: 0
  });
  const [allUrls, setAllUrls] = useState([]);
  const [des, setDes] = useState(null);
  // const [used, setUsed] = useState(null);
  const [urlImg, setUrlImg] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [tagsig, setTagsig] = useState('');
  const [imgurlig, setImgurlig] = useState('');
  const [assetAttached, setAssetAttached] = useState(null);
  const [bestTime, setBestTime] = useState(null);
  const [modalDismiss, setModalDismiss] = useState(createRef());
  const [resetForm, setResetForm] = useState(createRef());
  const [message, setMessage] = useState(useRef());
  const [checkFb, setCheckFb] = useState([]);
  const [checkGmb, setCheckGmb] = useState([]);
  const [checkTw, setCheckTw] = useState([]);
  const [checkIg, setCheckIg] = useState([]);
  const [query, setQuery] = useState('');
  const [isPromotional, setIsPromotional] = useState(false);
  const [isOffer, setIsOffer] = useState(false);
  const [isOriginalVisible, setIsOriginalVisible] = useState(true);

  let tagsPhrasesRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];

  useEffect(() => {
    if (savingPost || savingDraft || repairingPost) {
      savePost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDraft, savingPost, repairingPost]);

  useEffect(() => {
    if (props.post && !props.post.status) {
      if (
        props.post.start.getDate() ===
        getTime(props.currentClientTimeZone).getDate()
      )
        setDate(getTime(props.currentClientTimeZone));
      else {
        setDate(props.post.start);
      }
    } else if (props.post && props.post.status) {
      setIsCustomTime(true);

      if (typeof props.post.start === 'number') {
        setDate(new Date(props.post.start * 1000));
      } else {
        setDate(new Date(props.post.start));
      }

      let post = cloneDeep(props.post);
      let start = props.post ? new Date(props.post.start * 1000) : new Date();

      if (typeof start == 'number') {
        start = new Date(start * 1000);
      }

      message.value = post['title'];
      setPostDescription(post.title);
      setAssetUrl({
        value: post['images'] !== undefined ? post['images'][0]['url'] : ''
      });

      let { googleMyBusiness, facebook, twitter, instagram } = false;

      if (props.post && props.post.socialNetworks) {
        const { hashtagsig } = props.post;
        if (hashtagsig) {
          setTagsig(hashtagsig);
        }
        if (props.post.socialNetworks.googleMyBusiness) {
          googleMyBusiness = true;
        }
        if (props.post.socialNetworks.facebook) {
          facebook = true;
        }
        if (props.post.socialNetworks.twitter) {
          twitter = true;
        }
        if (props.post.socialNetworks.instagram) {
          instagram = true;
        }
      } else {
        if (props.currentClientSocialNetworks.googleMyBusiness) {
          googleMyBusiness = true;
        }
        if (props.currentClientSocialNetworks.facebook) {
          facebook = true;
        }
        if (props.currentClientSocialNetworks.twitter) {
          twitter = true;
        }
        if (props.currentClientSocialNetworks.instagram) {
          instagram = true;
        }
      }

      post['socialNetworks'] = {
        googleMyBusiness,
        facebook,
        twitter,
        instagram
      };
      setPost(post);

      if (date && !isCustomTime) {
        setDate(
          new Date(
            start.setHours(
              parseInt(props.currentClientBestTimes[days[start.getDay()]]),
              Math.random() * 59
            )
          )
        );
      }
      if (
        date !== null &&
        date !== props.post.start &&
        props.post.title === ''
      ) {
        setIsCustomTime(false);
      }

      post['images'] ? setPreview(post['images'][0]['url']) : setPreview('');
    } else if (
      !props.isCalendar &&
      props.show &&
      (date === null || bestTime === null) &&
      props.currentClientId
    ) {
      let noCalendarDate = getTime(props.currentClientTimeZone);
      setDate(getTime(props.currentClientTimeZone));
      setBestTime(
        new Date(
          noCalendarDate.setHours(
            parseInt(
              props.currentClientBestTimes[days[noCalendarDate.getDay()]]
            ),
            Math.random() * 59
          )
        )
      );
    }
    if (bestTime === null && props.post) {
      let start = props.post ? props.post.start : new Date();
      if (typeof start === 'number') {
        start = new Date(start);
      }

      let temp_date = start;
      setBestTime(
        new Date(
          temp_date.setHours(
            parseInt(props.currentClientBestTimes[days[temp_date.getDay()]]),
            Math.random() * 59
          )
        )
      );
    }
  }, [
    bestTime,
    date,
    days,
    isCustomTime,
    message,
    props.currentClientBestTimes,
    props.currentClientId,
    props.currentClientSocialNetworks.facebook,
    props.currentClientSocialNetworks.googleMyBusiness,
    props.currentClientSocialNetworks.instagram,
    props.currentClientSocialNetworks.twitter,
    props.currentClientTimeZone,
    props.isCalendar,
    props.post,
    props.show
  ]);
  useEffect(() => {
    let { googleMyBusiness, facebook, twitter, instagram } = false;
    if (props.currentClientSocialNetworks.googleMyBusiness) {
      googleMyBusiness = props.currentClientSocialNetworks.googleMyBusiness;
    }
    if (props.currentClientSocialNetworks.facebook) {
      facebook = props.currentClientSocialNetworks.facebook;
    }
    if (props.currentClientSocialNetworks.twitter) {
      twitter = props.currentClientSocialNetworks.twitter;
    }
    if (props.currentClientSocialNetworks.instagram) {
      instagram = props.currentClientSocialNetworks.instagram;
    }

    post['socialNetworks'] = {
      googleMyBusiness,
      facebook,
      twitter,
      instagram
    };
    setPost(post);
  }, [props.currentClientSocialNetworks, post]);

  const checkMessageAndReplaceTags = (message) => {
    let newMessage = message;
    let error = '';
    let mergeFields = props.currentClientMergeFields;

    // ====================== Check ~Name ======================

    if (/~Name/i.test(newMessage)) {
      if (mergeFields['~Name'].trim().length === 0) {
        error = 'The ~Name tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~Name)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~Name']))
        );
      }
    }

    // ====================== Check ~Address ===================

    if (/~Address/i.test(newMessage)) {
      if (mergeFields['~Address'].trim().length === 0) {
        error = 'The ~Address tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~Address)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~Address']))
        );
      }
    }

    // ====================== Check ~City ======================

    if (/~City/i.test(newMessage)) {
      if (mergeFields['~City'].trim().length === 0) {
        error = 'The ~City tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~City)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~City']))
        );
      }
    }

    // ====================== Check ~Phone ======================

    if (/~Phone/i.test(newMessage)) {
      if (mergeFields['~Phone'].trim().length === 0) {
        error = 'The ~Phone tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~Phone)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~Phone']))
        );
      }
    }

    // ====================== Check ~Website =======================

    if (/~Website/i.test(newMessage)) {
      if (
        typeof mergeFields['~Website'] == 'undefined' ||
        mergeFields['~Website'].trim().length === 0
      ) {
        error = 'The ~Website tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~Website)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~Website']))
        );
      }
    }

    // ====================== Check ~BestDish =====================

    if (/~BestDish/i.test(newMessage)) {
      if (mergeFields['~BestDish'].trim().length === 0) {
        error = 'The ~BestDish tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~BestDish)/i,
          (match) =>
            (newMessage = newMessage.replace(match, mergeFields['~BestDish']))
        );
      }
    }

    // ====================== Check ~BestService ===================

    if (/~BestService/i.test(newMessage)) {
      if (mergeFields['~BestService'].trim().length === 0) {
        error = 'The ~BestService tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~BestService)/i,
          (match) =>
            (newMessage = newMessage.replace(
              match,
              mergeFields['~BestService']
            ))
        );
      }
    }

    // ====================== Check ~BestProduct ====================

    if (/~BestProduct/i.test(newMessage)) {
      if (mergeFields['~BestProduct'].trim().length === 0) {
        error = 'The ~BestProduct tag has not been assigned.';
        return { status: true, message: error };
      } else {
        reactStringReplace(
          newMessage,
          /(~BestProduct)/i,
          (match) =>
            (newMessage = newMessage.replace(
              match,
              mergeFields['~BestProduct']
            ))
        );
      }
    }

    // ======================== Check ~ Tag ================================

    if (/~/.test(newMessage)) {
      error = 'Invalid ~ tag.';
      return { status: true, message: error };
    }

    return { status: false, message: newMessage };
  };
  const editTimeRecurrentDates = (RecurrentPosts, currentClientTimeZone) => {
    // TIME ZONE - TIME PUBLICATION + TIME LOCATION OR NAVIGATOR
    let timeZone = currentClientTimeZone;
    let timeZoneToUnix = moment(getTime(timeZone)).seconds(0).unix();
    let timeLocation = moment(new Date()).seconds(0).unix();
    const posts = RecurrentPosts.map((item) => {
      return Math.abs(timeZoneToUnix - item) + timeLocation;
    });

    return posts;
  };
  const saveCurrent = () => {
    setSavingPost(true);
  };
  const saveDraft = () => {
    setSavingDraft(true);
  };
  const repairPost = () => {
    setRepairingPost(true);
  };

  const addPost = async (post) => {
    if (post['images']) {
      let dimensions = await getSize(post['images'][0]['url']);

      if (dimensions.height < 250 || dimensions.width < 250) {
        Swal.fire({
          icon: 'error',
          title: `Your image is too small (${dimensions.width} x ${dimensions.height}), should be at least 800 x 600`,
          timer: 5000
        }).then((res) => {});
        setSavingPost(false);
        setSavingDraft(false);
        setRepairingPost(false);
        return;
      }
    }

    let clientId =
      post['client'] !== undefined
        ? post['client']['id']
        : props['currentClientId'];

    if (clientId === undefined) {
      return;
    }

    function setGooglePostType(post, type) {
      post[type] = true;
      post['facebook'] = false;
      post['twitter'] = false;
      post['instagram'] = false;
      post['googleMyBusiness'] = true;
    }

    if (isPromotional) {
      setGooglePostType(post, 'isPromotional');
    }

    if (isOffer) {
      setGooglePostType(post, 'isOffer');
    }

    const postBody = {
      message: post['title'],
      images: post['images'],
      schedule: post['start'], //unix UTC
      createdAt: moment.utc().unix(), //unix UTC
      socialNetworks: {
        facebook: post['facebook'] || false,
        twitter: post['twitter'] || false,
        googleMyBusiness: post['googleMyBusiness'] || false,
        instagram: post['instagram'] || false
      },
      isDraft: post['isDraft'],
      recurrentDates: recurrentDates,
      posted: false,
      isScheduled: false,
      createdBy: props.currentCustomerName,
      hashtagsig: post['hashtagsig'],
      isPromotional: post['isPromotional'] || false,
      isOffer: post['isOffer'] || false
    };
    postBody['clientId'] = clientId;
    if (post['id'] !== undefined) {
      postBody['postId'] = post['id'];
    }

    let url = post['id'] !== undefined ? API_POSTS_EDIT : API_POSTS;

    if (repairingPost) {
      url = API_POSTS_REPAIR;
    }

    axios
      .post(url, JSON.stringify(postBody))
      .then(async (response) => {
        if (response) {
          if (urlImg) {
            let img_info = {
              userid: props.currentClientId,
              link: urlImg,
              des: des,
              isUsed: true
            };

            await fetch(MANIPULATE_DESCRIPTION, {
              method: 'POST',
              body: JSON.stringify(img_info),
              headers: {
                'Content-Type': 'application/json'
              }
            });
          }
          if (urlImg) {
            setRefresh(!refresh);
          }
          let temp_used_state = urlImg;
          // setDate(null); // TEST IF IT WORKS
          setPost({
            socialNetworks: {
              facebook: true,
              twitter: true,
              googleMyBusiness: true,
              instagram: true
            }
          });
          setAssetUrl({
            value: ''
          });
          setSavingPost(false);
          setSavingDraft(false);
          setRepairingPost(false);
          setPreview(null);
          setShowRecurrent(false);
          setChecked(false);
          setRecurrentDates([]);
          setIsCustomTime(false);
          setPostDescription('');
          setShowEmojisMenu(false);
          setShowImgEditModal(false);
          setCroppedImg(null);
          setTextAreaCursorPostion({
            selectionStart: 0,
            selectionEnd: 0
          });
          setAllUrls([]);
          setDes(null);
          setIsPromotional(false);
          setIsOffer(false);
          setUrlImg(null);
          setRefresh(false);
          setTagsig('');
          setImgurlig('');
          setAssetAttached(null);
          setBestTime(null);
          setModalDismiss();
          setResetForm(null);
          setMessage(null);
          setCheckFb([]);
          setCheckGmb([]);
          setCheckTw([]);
          setCheckIg([]);
          Swal.fire({
            icon: 'success',
            title: 'Your post has been sent',
            showConfirmButton: false,
            timer: 1500
          });

          resetForm.click();
          modalDismiss.click();
          if (props?.callbackClose) {
            props.callBackClose();
          }

          if (props.callbackPost) {
            props.callbackPost(response, temp_used_state);
          }
        }
      })
      .catch((error) => {
        if (error) {
          Swal.fire({
            title: 'error',
            text: 'We have trouble adding the publication'
          }).then((res) => {});
          setSavingPost(false);
        }
        console.error(error);
      });
  };
  const savePost = () => {
    // setSavingPost(true);

    // ====================== Check a Message ======================

    // ========== Length ==========

    if (message.value.length < 3) {
      message.className = 'form-control error-field';
      message.placeholder = 'This field is required';

      Swal.fire('Error', '<span>Please add a message</span>').then((r) => {
        if (r.value) {
          setSavingPost(false);
          setSavingDraft(false);
          setRepairingPost(false);
        }
      });
      return;
    }

    // ========== Tags =============

    if (postDescription.includes('~')) {
      let checkMessage = checkMessageAndReplaceTags(postDescription);

      if (checkMessage.status) {
        Swal.fire('Error', `<span>${checkMessage.message}</span>`).then((r) => {
          if (r.value) {
            setSavingPost(false);
            setSavingDraft(false);
            setRepairingPost(false);
          }
        });
        return;
      } else {
        setPostDescription(checkMessage.message);
        message.value = checkMessage.message;
      }
    }

    // ====================== Check a Image ==========================

    if (
      assetUrl.value.length === 0 &&
      assetAttached.files.length === 0 &&
      !savingDraft
    ) {
      Swal.fire('Error', '<span>Please add a image</span>').then((r) => {
        if (r.value) {
          setSavingPost(false);
          setSavingDraft(false);
        }
      });
      return;
    }

    allUrls.map((url) => {
      return url.replace('/thumbnails', '');
    });
    // let temp_used = originalUrls.includes(assetUrl.value);
    // setUsed(temp_used);
    if (savingDraft) {
      if (croppedImg) {
        detectPost(3);
      } else if (
        assetUrl.value.length === 0 &&
        assetAttached.files[0] === undefined
      ) {
        uploadPost('', 0);
      } else if (assetAttached.files[0] !== undefined) {
        detectPost(2);
      } else if (
        assetUrl.value !== '' ||
        assetUrl.value !== post['images'][0]['url']
      ) {
        detectPost(1);
      }
    } else {
      if (croppedImg) {
        detectPost(3);
      } else if (assetAttached.files[0] !== undefined) {
        detectPost(2);
      } else if (
        assetUrl.value !== '' ||
        assetUrl.value !== post['images'][0]['url']
      ) {
        detectPost(1);
      }
    }
  };

  const detectPost = async (flag) => {
    let bodyLoadImage = {
      clientId: props.currentClientId
    };

    switch (flag) {
      case 1:
        bodyLoadImage['urlFile'] = assetUrl.value;
        uploadImage(bodyLoadImage, flag);
        break;
      case 2:
        fileToBase64(assetAttached, (res) => {
          bodyLoadImage['fileB64'] = res;
          uploadImage(bodyLoadImage, flag);
        });
        break;
      case 3:
        blobToBase64(croppedImg, (res) => {
          bodyLoadImage['fileB64'] = res;
          uploadImage(bodyLoadImage, flag);
        });
        break;
      default:
        break;
    }
  };

  const selectSn = (key, val) => {
    // let post = post;
    if (post['socialNetworks']) {
      post['socialNetworks'][key] = !post['socialNetworks'][key];
      //ACA
      setPost(post);
    }
  };
  const uploadPost = (urlImage, flag) => {
    let post1 = post;
    let timeZone = getTime(props.currentClientTimeZone);
    let rest = null;
    if (isCustomTime) {
      rest = moment.utc(timeZone).unix() - moment.utc(date).unix();
    } else if (!isCustomTime && bestTime !== null) {
      rest = moment.utc(timeZone).unix() - moment.utc(bestTime).unix();
    } else {
      rest = moment.utc(timeZone).unix() - moment.utc(date).unix();
    }
    let dateNow =
      (rest > 0 ? -600 : rest * -1) + moment.utc(new Date()).seconds(0).unix();

    post1['title'] = message.value;
    post1['start'] = dateNow;
    if (urlImage !== '') {
      post1['images'] = [
        { url: urlImage, text: urlImage.split('.com/')[1].split('.')[0] || '' }
      ];

      if (flag === 1) {
        post1['images'][0]['originalURL'] = preview;
      }
    }

    let tagsText = '';
    if (tagsPhrasesRef.current !== null) {
      const phrasesTags = Array.from(tagsPhrasesRef.current.childNodes);

      tagsText = phrasesTags.map((nodes) => {
        return nodes['childNodes'][0].data;
      });

      tagsText = tagsText.join('-n-');
    }

    post1['facebook'] = checkFb ? checkFb?.checked : false;
    post1['twitter'] = checkTw ? checkTw?.checked : false;
    post1['isDraft'] = savingDraft ? true : false;
    post1['googleMyBusiness'] = checkGmb ? checkGmb?.checked : false;
    post1['instagram'] = checkIg ? checkIg?.checked : false;
    post1['startEditable'] = true;
    post1['backgroundColor'] = '#039be5';
    post1['borderColor'] = '#0168fa';
    post1['textColor'] = '#fff';
    post1['imgurlig'] = imgurlig;
    post1['hashtagsig'] = tagsText;
    addPost(post1);
  };

  const uploadImage = (bodyLoadImage, flag) => {
    if (flag === 2) {
      let file = assetAttached.files[0];
      let newFileName = uuidv4();

      const config = {
        bucketName: AWS_BUCKET,
        region: AWS_REGION,
        accessKeyId: AWS_KEY,
        secretAccessKey: AWS_SECRET,
        s3Url: AWS_S3URL
      };

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data.status === 204) {
          setAssetUrl({ value: data.location });
          uploadPost(data.location, flag);
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'The url is not valid!'
          }).then((res) => {
            if (res.value) {
              assetUrl.value = '';
              setSavingPost(false);
            }
          });
        }
      });
    } else {
      axios
        .post(API_ASSETS, bodyLoadImage)
        .then((response) => {
          if (response['data']['Location']) {
            let res =
              response['data']['Location'].substring(
                0,
                response['data']['Location'].lastIndexOf('.')
              ) + '.jpeg';
            setAssetUrl({ value: res });
            uploadPost(res, flag);
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error!',
            text: 'The url is not valid!'
          }).then((res) => {
            if (res.value) {
              setAssetUrl({ value: '' });

              setSavingPost(false);
            }
          });
        });
    }
  };
  const onChange = (date) => {
    if (date instanceof Date) {
      setDate(date);
    }
  };

  const handleRecurrentCheck = () => {
    setShowRecurrent(!showRecurrent);

    setRecurrentDates(date);
    setChecked(!checked);
  };

  const handleCustomTime = () => {
    //PENDING
    setIsCustomTime(!isCustomTime);
    if (!isCustomTime) {
      let temp_date = cloneDeep(date);

      setBestTime(
        new Date(
          temp_date.setHours(
            parseInt(props.currentClientBestTimes[days[temp_date.getDay()]]),
            Math.random() * 59
          )
        )
      );
    } else {
      // POST SCHEDULE
      if (!props.isCalendar) {
        setDate(getTime(props.currentClientTimeZone));
      } else {
        if (props.post && props.post.status) {
          // setDate(editDate);
        } else {
          // setDate(date);
          // setBestTime(
          //   new Date(
          //     date.setHours(
          //       parseInt(props.currentClientBestTimes[days[date.getDay()]]),
          //       Math.random() * 59
          //     )
          //   )
          // );
        }
      }
    }
  };
  const setAppImage = (event) => {
    handlePreview(event);
  };
  const checkImg = (param) => {
    //if (allUrls.length < 1) {
    let urlOriginal = param[1];
    let thumbUrl = urlOriginal.split('/');

    urlOriginal = `${thumbUrl[0]}//${thumbUrl[2]}/${thumbUrl[3]}/${thumbUrl[4]}/thumbnails/${thumbUrl[5]}`;
    setAllUrls(param[0]);
    setUrlImg(urlOriginal);
    setDes(param[2]);
    //}
  };

  const handlePreview = (event) => {
    setAssetUrl({ value: event.target.value });
    event.target.files
      ? setPreview(URL.createObjectURL(event.target.files[0]))
      : setPreview(event.target.value);
  };

  const getSize = (imageUrl) => {
    const options = url.parse(imageUrl);
    return new Promise((resolve, reject) => {
      http.get(options, function (response) {
        const chunks = [];
        response
          .on('data', function (chunk) {
            chunks.push(chunk);
          })
          .on('end', function () {
            const buffer = Buffer.concat(chunks);
            resolve(sizeOf(buffer));
          });
      });
    });
  };

  const getTime = (currentClientTimeZone) => {
    let date = null;

    switch (currentClientTimeZone) {
      case 'Eastern Daylight Time - (GMT-4)':
        date = moment(new Date())
          .tz('America/New_York')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Central Daylight Time - (GMT-5)':
        date = moment(new Date())
          .tz('America/Chicago')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Mountain Daylight Time - (GMT-6)':
        date = moment(new Date())
          .tz('America/Denver')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Mountain Standard Time - (GMT-7)':
        date = moment(new Date())
          .tz('America/Phoenix')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Pacific Daylight Time - (GMT-7)':
        date = moment(new Date())
          .tz('America/Los_Angeles')
          .format('YYYY-MM-DDTHH:mm');
        break;
      case 'Alaska Daylight Time - (GMT-8)':
        date = moment(new Date())
          .tz('America/Anchorage')
          .format('YYYY-MM-DDTHH:mm');
        break;
      default:
        date = moment(new Date())
          .tz('Pacific/Honolulu')
          .format('YYYY-MM-DDTHH:mm');
        break;
    }

    return new Date(date);
  };

  const renderSocialNetworks = () => {
    const { currentClientSocialNetworks } = props;
    return (
      <React.Fragment>
        <h5 className="card-title mt-3">Social Networks</h5>
        <h6 className="card-subtitle mb-2 text-muted">
          Please select a Social Network
        </h6>
        <div className="d-flex flex-row">
          <div>
            {currentClientSocialNetworks.facebook && (
              <div className="custom-control custom-checkbox mr-5">
                <input
                  ref={(inp) => {
                    setCheckFb(inp);
                  }}
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckFb"
                  onChange={(e) => {
                    selectSn('facebook', e.target.value);
                    setCheckFb(e.target.value);
                  }}
                  checked={
                    post['socialNetworks'] && post['socialNetworks']['facebook']
                  }
                />
                <label
                  style={{ marginLeft: '3px' }}
                  className="custom-control-label"
                  htmlFor="customCheckFb">
                  Facebook
                </label>
              </div>
            )}

            {currentClientSocialNetworks.googleMyBusiness && (
              <div className="custom-control custom-checkbox mr-5">
                <input
                  ref={(inp) => {
                    setCheckGmb(inp);
                  }}
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckGmb"
                  onChange={(e) => {
                    selectSn('googleMyBusiness', e.target.value);
                    setCheckGmb(e.target.value);
                  }}
                  checked={
                    post['socialNetworks'] &&
                    post['socialNetworks']['googleMyBusiness']
                  }
                />
                <label
                  style={{ marginLeft: '3px' }}
                  className="custom-control-label"
                  htmlFor="customCheckGmb">
                  Google Business Profile
                </label>
              </div>
            )}
          </div>

          <div>
            {currentClientSocialNetworks.twitter && (
              <div className="custom-control custom-checkbox mr-5">
                <input
                  ref={(inp) => {
                    setCheckTw(inp);
                  }}
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckTw"
                  onChange={(e) => {
                    selectSn('twitter', e.target.value);
                    setCheckTw(e.target.value);
                  }}
                  checked={
                    post['socialNetworks'] && post['socialNetworks']['twitter']
                  }
                />
                <label
                  style={{ marginLeft: '3px' }}
                  className="custom-control-label"
                  htmlFor="customCheckTw">
                  Twitter
                </label>
              </div>
            )}

            {currentClientSocialNetworks.instagram && (
              <div className="custom-control custom-checkbox mr-5">
                <input
                  ref={(inp) => {
                    setCheckIg(inp);
                  }}
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheckIg"
                  onChange={(e) => {
                    selectSn('instagram', e.target.value);
                    setCheckIg(e.target.value);
                  }}
                  checked={
                    post['socialNetworks'] &&
                    post['socialNetworks']['instagram']
                  }
                />
                <label
                  style={{ marginLeft: '3px' }}
                  className="custom-control-label"
                  htmlFor="customCheckIg">
                  Instagram
                </label>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderBestTime = () => {
    const { currentClientTimeZone } = props;
    const currentDate = new Date();
    return (
      <div style={{ border: 'none' }} className="card mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mb-0 d-flex justify-content-between">
                <div>{isCustomTime ? 'Post Schedule' : 'Best Time'}</div>
                <div>
                  <label className="switch float-right">
                    <input
                      // ref={(input) => setBestTime(input)}
                      type="checkbox"
                      onChange={() => handleCustomTime()}
                      checked={isCustomTime}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </h5>
              {isCustomTime && (
                <h6 className="card-subtitle mt-3 mb-2 text-muted float-left">
                  Please select date and time
                </h6>
              )}
              <br />

              {isCustomTime && (
                <DateTimePicker
                  onChange={onChange}
                  disableClock
                  value={date}
                  minDate={getTime(currentClientTimeZone)}
                  name="postSchedule"
                  required
                  showTime={{ use12Hours: true }}
                  format="dd/MM/yyyy hh:mm a"
                  clearIcon={null}
                  calendarIcon={null}
                />
              )}
              {date == null ||
              typeof date == 'number' ||
              props.currentClientBestTimes == null ||
              isCustomTime ? (
                ''
              ) : getTime(currentClientTimeZone).getHours() >
                  parseInt(props.currentClientBestTimes[days[date.getDay()]]) &&
                date.getDate() === currentDate.getDate() ? (
                <h6 className="card-subtitle text-muted">
                  <i className="fas fa-exclamation-triangle" /> Your best time
                  posting has passed
                </h6>
              ) : (
                ''
              )}

              {isCustomTime !== false ? (
                <div className="custom-control custom-checkbox mt-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="recurrentCheck"
                    onChange={handleRecurrentCheck}
                    checked={checked}
                  />
                  <label
                    style={{ marginLeft: '3px' }}
                    className="custom-control-label"
                    htmlFor="recurrentCheck">
                    Recurring post
                  </label>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-6">
              {!isCustomTime && (
                <h3 className="mb-0 text-center">
                  {bestTime
                    ? bestTime.toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })
                    : ''}
                </h3>
              )}
              {showRecurrent && isCustomTime ? (
                <RecurrentPosts
                  date={date}
                  recurrentDates={(dates) =>
                    setRecurrentDates(
                      editTimeRecurrentDates(dates, currentClientTimeZone)
                    )
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImgUrl = () => (
    <>
      <h5 className="card-title">Image URL</h5>
      <h6 className="card-subtitle mb-2 text-muted">
        Please add an url for an image
      </h6>
      <input
        value={assetUrl.value}
        className="form-control"
        name="assetUrl"
        onChange={handlePreview}
      />
    </>
  );

  const renderImgFile = () => (
    <React.Fragment>
      <h5 className="card-title mt-3">Image File</h5>
      <h6 className="card-subtitle mb-2 text-muted">
        Please add a file for an image
      </h6>
      <input
        type="file"
        ref={(im) => {
          setAssetAttached(im);
        }}
        className="form-control attach-file"
        name="assetUrl"
        onChange={handlePreview}
      />
    </React.Fragment>
  );

  const renderImgPreview = () => (
    <div className="card" style={{ maxHeight: '13rem', border: 'none' }}>
      <div className="card-body overflow-hidden">
        <h5 className="card-title">Image Preview</h5>
        <h6 className="card-subtitle mb-2 text-muted">Your Image preview</h6>
        <div className="row">
          <div
            style={{
              backgroundImage: `url(${croppedImg ? croppedImg : preview})`
            }}
            className="img-preview">
            {croppedImg || preview ? '' : <label>No image to preview</label>}
          </div>
          {preview && (
            <div
              className="edit-img-btn shadow"
              onClick={() => {
                setShowImgEditModal(true);
              }}>
              <i className="fa fa-fw fa-pencil-alt" />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // ================ Drag and Drop Merge Fields Tags ===============

  const onDragStart = (e, key) => {
    e.dataTransfer.setData('key', key);
  };

  const handleClickStart = (e, key) => {
    e.preventDefault();
    setPostDescription(postDescription + key + ' ');
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    setPostDescription(postDescription + e.dataTransfer.getData('key') + ' ');
  };

  const { currentClientTimeZone, currentClientMergeFields } = props;

  // ====================== Merge Fields Tags ======================
  let mergeFields = [];

  if (currentClientMergeFields) {
    for (let key in currentClientMergeFields) {
      if (
        currentClientMergeFields[key] &&
        currentClientMergeFields[key].trim().length !== 0
      ) {
        mergeFields.push(
          <li key={key} className="list-inline-item">
            <button
              onClick={(e) => handleClickStart(e, key)}
              onDragStart={(e) => onDragStart(e, key)}
              className="mergeFields"
              draggable
              type="button">
              {key}
            </button>
          </li>
        );
      }
    }
  }
  const getTimeZoneClock = (currentClientTimeZone) => {
    let timeZoneClock = null;
    switch (currentClientTimeZone) {
      case 'Eastern Daylight Time - (GMT-4)':
        timeZoneClock = 'America/New_York';
        break;
      case 'Central Daylight Time - (GMT-5)':
        timeZoneClock = 'America/Chicago';
        break;
      case 'Mountain Daylight Time - (GMT-6)':
        timeZoneClock = 'America/Denver';
        break;
      case 'Mountain Standard Time - (GMT-7)':
        timeZoneClock = 'America/Phoenix';
        break;
      case 'Pacific Daylight Time - (GMT-7)':
        timeZoneClock = 'America/Los_Angeles';
        break;
      case 'Alaska Daylight Time - (GMT-8)':
        timeZoneClock = 'America/Anchorage';
        break;
      default:
        timeZoneClock = 'Pacific/Honolulu';
        break;
    }
    return timeZoneClock;
  };

  const [value, setValue] = useState(0);

  const handleIndexChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOfferSwitchChange = () => {
    setIsOriginalVisible(false);
    setIsOffer(true);
  };

  return (
    <div
      className={`${!savingPost ? 'disable' : ''} ${isOffer ? 'hidden' : ''}`}>
      <div
        className="modal fade"
        id="newPost"
        tabIndex={-1}
        data-bs-keyboard="false" // Desactiva el cierre con Esc
        data-bs-backdrop="static" // Desactiva cierre al hacer clic fuera
        // role="dialog"
        aria-labelledby="addNewGroupLabel"
        aria-hidden="true"
        ref={ref}>
        <div
          id="modal-new-post"
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document">
          <div className={`modal-content tx-14 ${savingPost ? 'disable' : ''}`}>
            <div className="modal-header">
              <div>
                <h6 className="modal-title" id="addNewGroupLabel">
                  New Post - {currentClientTimeZone}
                </h6>
                <div className="pd-y-20 pd-x-15 text-current-time text-current">
                  <label
                    className="tx-uppercase tx-sans tx-medium tx-spacing-1 text-dark m-0 pd-l-10 mg-b-10"
                    style={{ color: '#001737 important' }}>
                    Current Time
                  </label>

                  <div
                    className="text-current-time text-center"
                    style={{ lineHeigh: '1em' }}>
                    <Clock
                      format={'HH:mm:ss'}
                      className="ml-2 "
                      ticking={true}
                      timezone={getTimeZoneClock(currentClientTimeZone)}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                {props.currentClientSocialNetworks.googleMyBusiness && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '0.5em',
                        marginLeft: '0.5em'
                      }}>
                      <h6
                        style={{
                          marginTop: '0.5em',
                          marginLeft: '0.5em',
                          marginRight: '0.5em'
                        }}>
                        Add Offer
                      </h6>
                      <label className="switch">
                        <input
                          type="checkbox"
                          onChange={handleOfferSwitchChange}
                          checked={isOffer}
                        />
                        <span className="slider round custom-slider" />
                      </label>
                    </div>

                    {isAuthenticated &&
                      (currentRole === 'superAdmin' ||
                        allowedEmails.includes(currentEmail)) &&
                      isOffer && (
                        <OfferModal
                          onClose={() => {
                            setIsOffer(false);
                            setIsOriginalVisible(true);
                          }}
                          onBack={() => {
                            setIsOffer(false);
                            setIsOriginalVisible(true);
                          }}
                          props={props}
                        />
                      )}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '0.5em',
                        marginLeft: '0.5em'
                      }}>
                      <h6
                        style={{
                          marginTop: '0.5em',
                          marginLeft: '0.5em',
                          marginRight: '0.5em'
                        }}>
                        Google Post
                      </h6>
                      <label className="switch float-right">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsPromotional(!isPromotional);
                            !isCustomTime && handleCustomTime();
                            isPromotional && isCustomTime && handleCustomTime();
                            !isCustomTime && !checked && handleRecurrentCheck();
                          }}
                          checked={isPromotional}
                        />
                        <span className="slider round custom-slider" />
                      </label>
                    </div>
                  </>
                )}
                <button
                  type="button"
                  style={{ marginLeft: 5, marginRight: 5 }}
                  className="btn btn-secondary tx-13"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    resetForm.click();
                    if (props?.callbackClose) {
                      props.callBackClose();
                    }
                    setDate(null);
                    setPost({
                      socialNetworks: {
                        facebook: true,
                        twitter: true,
                        googleMyBusiness: true,
                        instagram: true
                      }
                    });
                    setAssetUrl({
                      value: ''
                    });

                    setSavingPost(false);
                    setSavingDraft(false);
                    setPreview(null);
                    setShowRecurrent(false);
                    setChecked(false);
                    setRecurrentDates([]);
                    setIsCustomTime(false);
                    setPostDescription('');
                    setShowEmojisMenu(false);
                    setShowImgEditModal(false);
                    setCroppedImg(null);
                    setTextAreaCursorPostion({
                      selectionStart: 0,
                      selectionEnd: 0
                    });
                    setAllUrls([]);
                    setDes(null);
                    setIsPromotional(false);
                    setIsOffer(false);
                    setUrlImg(null);
                    setRefresh(false);
                    setTagsig('');
                    setImgurlig('');
                    setAssetAttached(null);
                    setBestTime(null);
                    setModalDismiss();
                    setResetForm(null);
                    setMessage(null);
                    setCheckFb([]);
                    setCheckGmb([]);
                    setCheckTw([]);
                    setCheckIg([]);
                    setQuery('');
                    setValue(0);
                  }}
                  ref={(button) => {
                    setModalDismiss(button);
                  }}>
                  Close
                </button>
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <ButtonLoad
                    state={savingDraft}
                    btn={'btn-primary btn-save-draft'}
                    icon={'fa-pencil-ruler'}
                    text={' Save draft'}
                    callback={() => {
                      saveDraft();
                    }}
                  />
                </div>
                {post.isRepairingPost && (
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <ButtonLoad
                      style={{ marginLeft: 5 }}
                      btn={'btn-primary btn-success btn-publish-post'}
                      icon={'fa-wrench'}
                      text={' Edit Post'}
                      callback={() => {
                        repairPost();
                      }}
                    />
                  </div>
                )}
                {!post.isRepairingPost && (
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <ButtonLoad
                      state={savingPost}
                      style={{ marginLeft: 5 }}
                      btn={'btn-primary btn-success btn-publish-post'}
                      icon={'fa-check-square'}
                      text={' Publish Post'}
                      callback={() => {
                        saveCurrent();
                      }}
                    />
                  </div>
                )}
                <button
                  // type="button"
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: '0px',
                    margin: '0px',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    if (props?.callbackClose) {
                      props.callBackClose();
                    }
                    setDate(null);
                    setPost({
                      socialNetworks: {
                        facebook: true,
                        twitter: true,
                        googleMyBusiness: true,
                        instagram: true
                      }
                    });
                    setAssetUrl({
                      value: ''
                    });

                    setSavingPost(false);
                    setSavingDraft(false);
                    setRepairingPost(false);
                    setPreview(null);
                    setShowRecurrent(false);
                    setChecked(false);
                    setRecurrentDates([]);
                    setIsCustomTime(false);
                    setPostDescription('');
                    setShowEmojisMenu(false);
                    setShowImgEditModal(false);
                    setCroppedImg(null);
                    setTextAreaCursorPostion({
                      selectionStart: 0,
                      selectionEnd: 0
                    });
                    setAllUrls([]);
                    setDes(null);
                    setIsPromotional(false);
                    setIsOffer(false);
                    setUrlImg(null);
                    setRefresh(false);
                    setTagsig('');
                    setImgurlig('');
                    setAssetAttached(null);
                    setBestTime(null);
                    setModalDismiss();
                    setResetForm(null);
                    setMessage(null);
                    setCheckFb([]);
                    setCheckGmb([]);
                    setCheckTw([]);
                    setCheckIg([]);
                    setQuery('');
                    setValue(0);
                    resetForm.click();
                  }}>
                  <i
                    style={{ color: '#B3B3B3' }}
                    className="fas fa-fw fa-sm fa-times feather"
                  />
                </button>
              </div>
            </div>
            <form>
              <input
                hidden="true"
                className="btn-reset"
                type="reset"
                ref={(arc) => {
                  setResetForm(arc);
                }}
                value="reset"
              />
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-5">
                    <div style={{ border: 'none' }} className="card">
                      <div className="card-body">
                        <ContentGuidelineTooltip newPostProps={props} />
                        <h5
                          className="card-title"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}>
                          Post Message{' '}
                          <span
                            style={{
                              fontWeight: 400,
                              fontSize: 'small',
                              color:
                                checkTw?.checked && message?.value?.length > 280
                                  ? 'red'
                                  : message?.value?.length >= 2000
                                  ? 'red'
                                  : 'black'
                            }}>
                            {checkTw?.checked && message?.value?.length > 280
                              ? 'Twitter limit characters '
                              : null}
                            {`${
                              message?.value?.length > 0
                                ? message?.value?.length
                                : ''
                            } 
                                
                                ${
                                  message?.value?.length &&
                                  message?.value?.length < 2000
                                    ? `/ ${2000 - message?.value?.length}`
                                    : ''
                                }
                              `}
                          </span>
                        </h5>

                        <h6 className="card-subtitle mb-2 text-muted">
                          Please write the post message content
                          <button
                            type="button"
                            className="btn btn-link float-right p-0"
                            onClick={() => {
                              setShowEmojisMenu(!showEmojisMenu);
                            }}>
                            <span role="img" aria-label="add-emoji">
                              😊
                            </span>
                          </button>
                        </h6>
                        <div
                          className={`
                            ${showEmojisMenu ? 'd-block' : 'd-none'}
                            position-absolute shadow`}
                          style={{ zIndex: '2', right: '0px' }}>
                          {showEmojisMenu ? (
                            <Picker
                              onEmojiClick={(e, emoji) => {
                                const { selectionStart, selectionEnd } =
                                  textAreaCursorPostion;
                                setMessage(emoji.emoji);
                                setShowEmojisMenu(false);
                                let newMsg = postDescription.split('');

                                newMsg.splice(
                                  selectionStart,
                                  selectionEnd - selectionStart,
                                  emoji.emoji
                                );

                                setPostDescription(newMsg.join(''));
                              }}
                            />
                          ) : null}
                        </div>
                        <textarea
                          ref={(msg) => {
                            if (
                              message?.value?.length <= 2000 ||
                              !message?.value
                            ) {
                              setMessage(msg);
                            }
                          }}
                          onClick={() => {
                            const { selectionStart, selectionEnd } = message;
                            setTextAreaCursorPostion({
                              selectionStart,
                              selectionEnd
                            });
                          }}
                          onChange={(e) => {
                            message.className = 'form-control';
                            if (
                              message?.value?.length <= 2000 ||
                              !message?.value
                            ) {
                              setPostDescription(e.target.value);
                              setTextAreaCursorPostion({
                                selectionStart: e.target.value.length,
                                selectionEnd: e.target.value.length
                              });
                            }
                          }}
                          onFocus={() => {
                            setShowEmojisMenu(false);
                          }}
                          value={postDescription}
                          className="form-control"
                          rows="2"
                          required
                          onDragOver={(e) => onDragOver(e)}
                          onDrop={(e) => onDrop(e)}
                        />
                        <div style={{ marginTop: '15px' }}>
                          <ul
                            className="list-inline list-inline-skills"
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            {mergeFields}
                          </ul>
                        </div>

                        <div className="igtags-socialnetwork">
                          {!isPromotional && (
                            <div>
                              <IGTagsPost
                                tagsPhrasesRef={tagsPhrasesRef}
                                size="smaller"
                                idClient={props['currentClientId']}
                                tagsPost={tagsig}
                                isIgActive={
                                  props.currentClientSocialNetworks?.instagram
                                }
                              />
                            </div>
                          )}
                          <div
                            className="mr-3"
                            style={{
                              pointerEvents: post.isRepairingPost
                                ? 'none'
                                : 'auto',
                              opacity: post.isRepairingPost ? 0.5 : 1
                            }}>
                            {!isPromotional && renderSocialNetworks()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        pointerEvents: post.isRepairingPost ? 'none' : 'auto',
                        opacity: post.isRepairingPost ? 0.5 : 1
                      }}>
                      {renderBestTime()}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-md-12">{renderImgPreview()}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div style={{ border: 'none' }} className="card">
                          <div className="card-body">
                            {renderImgUrl()}
                            {renderImgFile()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    {!isPromotional ? (
                      <SearchContext.Provider value={{ query, setQuery }}>
                        <TabMenu state={{ value, handleIndexChange }}>
                          <UserGallery
                            setAppImage={setAppImage}
                            checkImg={checkImg}
                            userId={props.currentClientId}
                            refresh={refresh}
                          />
                          <ContentGallery
                            setAppImage={setAppImage}
                            userId={props.currentClientId}
                            refresh={refresh}
                          />
                          <ClientGallery
                            setAppImage={setAppImage}
                            userId={props.currentClientId}
                            refresh={refresh}
                          />

                          <Pixabay setAppImage={setAppImage} />
                          <Pexels setAppImage={setAppImage} />
                          <Unsplash setAppImage={setAppImage} />
                        </TabMenu>
                      </SearchContext.Provider>
                    ) : (
                      <GoogleGallery
                        setAppImage={setAppImage}
                        userId={props.currentClientId}
                        refresh={refresh}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* HERE PUT */}
              {showImgEditModal && (
                <EditImgModal
                  img={preview}
                  storeOriginal={(img) => {
                    setImgurlig(img);
                  }}
                  handleClose={() => setShowImgEditModal(false)}
                  onUpdate={(img) => {
                    setCroppedImg(img);
                  }}
                  clientId={props.currentClientId}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

const fileToBase64 = (file, callBack) => {
  file = file.files[0];
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function () {
    callBack(reader.result);
  };
  reader.onerror = function (error) {
    console.error('Error: ', error);
  };
};

const blobToBase64 = (blob, callBack) => {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    let recoveredBlob = xhr.response;
    let reader = new FileReader();
    reader.onload = function () {
      callBack(reader.result);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
    reader.readAsDataURL(recoveredBlob);
  };
  xhr.open('GET', blob);
  xhr.send();
};

export default NewPost;
