import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment-timezone';

const RenderBestTimeOffer = ({
  currentClientTimeZone,
  currentClientBestTimes,
  onChange
}) => {
  const [isCustomTime, setIsCustomTime] = useState(false);
  const [date, setDate] = useState(() => {
    const initialDate = getTime(currentClientTimeZone);
    return initialDate instanceof Date && !isNaN(initialDate)
      ? initialDate
      : new Date();
  });

  const handleCustomTime = () => {
    setIsCustomTime(!isCustomTime);
    if (!isCustomTime) {
      setDate(getTime(currentClientTimeZone));
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate instanceof Date && !isNaN(newDate)) {
      setDate(newDate);
      if (onChange) {
        onChange(newDate);
      }
    }
  };

  return (
    <div className="card mt-3" style={{ border: 'none' }}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h5 className="mb-0 d-flex justify-content-between">
              <div>{isCustomTime ? 'Post Schedule' : 'Best Time'}</div>
              <div>
                <label className="switch float-right">
                  <input
                    type="checkbox"
                    onChange={handleCustomTime}
                    checked={isCustomTime}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </h5>

            {isCustomTime && (
              <h6 className="card-subtitle mt-3 mb-2 text-muted float-left">
                Please select date and time
              </h6>
            )}

            {isCustomTime && (
              <DateTimePicker
                onChange={handleDateChange}
                disableClock
                value={date instanceof Date && !isNaN(date) ? date : new Date()}
                minDate={getTime(currentClientTimeZone)}
                name="postSchedule"
                required
                format="dd/MM/yyyy hh:mm a"
                clearIcon={null}
                calendarIcon={null}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const getTime = (currentClientTimeZone) => {
  const timeZonesMap = {
    'Eastern Daylight Time - (GMT-4)': 'America/New_York',
    'Central Daylight Time - (GMT-5)': 'America/Chicago',
    'Mountain Daylight Time - (GMT-6)': 'America/Denver',
    'Mountain Standard Time - (GMT-7)': 'America/Phoenix',
    'Pacific Daylight Time - (GMT-7)': 'America/Los_Angeles',
    'Alaska Daylight Time - (GMT-8)': 'America/Anchorage'
  };

  const validTimeZone =
    timeZonesMap[currentClientTimeZone] || 'Pacific/Honolulu';
  return moment().tz(validTimeZone).toDate();
};

export default RenderBestTimeOffer;
