import React, { useRef, useState } from 'react';
import S3 from 'react-aws-s3';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import axios from 'axios';
import {
  AWS_KEY,
  AWS_SECRET,
  AWS_REGION,
  AWS_BUCKET,
  AWS_S3URL
} from '../../../utils';

const ImageUploader = ({ setImageData }) => {
  const [preview, setPreview] = useState('');
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const config = {
    bucketName: AWS_BUCKET,
    region: AWS_REGION,
    accessKeyId: AWS_KEY,
    secretAccessKey: AWS_SECRET,
    s3Url: AWS_S3URL
  };

  const s3Client = new S3(config);

  const uploadToS3 = async (file, originalURL) => {
    setUploading(true);
    const fileName = `${uuidv4()}-${file.name || 'image-uploaded'}`;

    try {
      const response = await s3Client.uploadFile(file, fileName);
      if (response.status === 204) {
        const imageUrl = response.location;
        setPreview(imageUrl);
        setImageData({
          originalURL: originalURL || imageUrl,
          text: file.name || fileName,
          url: imageUrl
        });
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to upload image', 'error');
    }
    setUploading(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      uploadToS3(file, null);
    }
  };

  const handleUrlChange = async (event) => {
    const imageUrl = event.target.value;
    setPreview(imageUrl);

    try {
      setUploading(true);
      const response = await axios.get(imageUrl, { responseType: 'blob' });

      const file = new File([response.data], `${uuidv4()}.jpg`, {
        type: response.headers['content-type']
      });

      uploadToS3(file, imageUrl);
    } catch (error) {
      Swal.fire('Error', 'Failed to fetch the image from the URL', 'error');
    }
  };

  return (
    <div>
      {preview && (
        <div style={{ marginBottom: '1em', textAlign: 'center' }}>
          <img
            src={preview}
            alt="Preview"
            style={{
              maxWidth: '100%',
              maxHeight: '200px',
              borderRadius: '8px'
            }}
          />
        </div>
      )}

      <div
        style={{
          textAlign: 'center',
          padding: '1.5em',
          border: '2px dashed #ccc',
          borderRadius: '8px',
          cursor: 'pointer'
        }}
        onClick={() => fileInputRef.current.click()}>
        <p>Drag and drop images here or</p>
        <button
          style={{
            backgroundColor: '#3766a8',
            color: 'white',
            border: 'none',
            padding: '0.75em 1.5em',
            borderRadius: '8px',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}>
          Select images
        </button>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>

      <div style={{ marginTop: '1em' }}>
        <h5>Image URL</h5>
        <input
          type="text"
          className="form-control"
          onChange={handleUrlChange}
          placeholder="Enter image URL"
        />
      </div>

      {uploading && <p>Uploading...</p>}
    </div>
  );
};

export default ImageUploader;
