import React from 'react';
import './ButtonLoad.scss';

const ButtonLoad = (props) => {
  return (
    <button
      type={props.type || 'button'}
      className={`btn ${props.btn} tx-13`}
      onClick={(e) => {
        props.callback(e);
      }}
      disabled={props.state || props.disabled}>
      {/* Icon visible solo cuando props.state es false */}
      <i
        className={`fas fa-fw ${props.icon}`}
        style={{ display: !props.state ? 'inline-block' : 'none' }}></i>

      {/* "Loading..." visible solo cuando props.state es true */}
      <span style={{ display: props.state ? 'inline' : 'none' }}>
        Loading...
      </span>

      {/* Texto visible solo cuando props.state es false */}
      <span style={{ display: !props.state ? 'inline' : 'none' }}>
        {props.text}
      </span>
    </button>
  );
};

export default ButtonLoad;
