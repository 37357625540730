import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_POSTS } from '../../../utils';
import RenderBestTimeOffer from './RenderBestTimeOffer';
import ImageUploader from './ImageUploader';

const OfferModal = ({ onClose, onBack, props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [offerTitle, setOfferTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [offerDetails, setOfferDetails] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [redeemLink, setRedeemLink] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [imageData, setImageData] = useState({
    originalURL: '',
    text: '',
    url: ''
  });
  const [scheduleDate, setScheduleDate] = useState(null);

  const handleClose = () => {
    onClose();
    const newPostModal = document.querySelector('[data-bs-dismiss="modal"]');
    if (newPostModal) {
      newPostModal.click();
    }
  };

  const handleScheduleChange = (selectedDate) => {
    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      setScheduleDate(Math.floor(selectedDate.getTime() / 1000));
    } else {
      setScheduleDate(null);
    }
  };

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handlePost = async () => {
    const parseDate = (dateString) => {
      if (!dateString) return {};
      const date = new Date(dateString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    };

    const postBody = {
      clientId: props.currentClientId,
      message: offerTitle,
      images: [
        {
          originalURL: imageData.originalURL || '',
          text: imageData.text || '',
          url: imageData.url || ''
        }
      ],
      schedule: scheduleDate,
      createdAt: Math.floor(Date.now() / 1000),
      socialNetworks: {
        facebook: false,
        twitter: false,
        googleMyBusiness: true,
        instagram: false
      },
      isDraft: false,
      recurrentDates: [],
      posted: false,
      isScheduled: false,
      createdBy: props.currentCustomerName,
      hashtagsig: '',
      isPromotional: false,
      isOffer: true,
      offer: {
        title: offerTitle,
        schedule: {
          startDate: parseDate(startDate),
          endDate: parseDate(endDate)
        },
        summary: offerDetails,
        offer: {
          couponCode: voucherCode,
          redeemOnlineUrl: redeemLink,
          termsConditions: additionalNotes
        }
      }
    };

    // 🔹 Solo agregamos callToAction si hay un URL válido
    if (redeemLink.trim() !== '') {
      postBody.offer.callToAction = {
        actionType: 'ORDER',
        url: redeemLink
      };
    }

    try {
      const response = await axios.post(API_POSTS, JSON.stringify(postBody), {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Your post has been sent',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          handleClose();
          window.location.reload();
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'We have trouble adding the publication'
      });
      console.error(error);
    }
  };

  const Footer = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1em',
        marginTop: '1.5em'
      }}>
      <button
        onClick={onClose}
        style={{
          padding: '0.75em 1.5em',
          border: '1px solid #ccc',
          borderRadius: '8px',
          background: 'white',
          color: '#677793',
          cursor: 'pointer'
        }}>
        Cancel
      </button>
      <button
        onClick={handlePost}
        style={{
          padding: '0.75em 1.5em',
          border: 'none',
          borderRadius: '8px',
          background: '#3766a8',
          color: 'white',
          cursor: 'pointer'
        }}>
        Post Offer
      </button>
    </div>
  );

  return (
    <div
      className="modal show d-block"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1050
      }}>
      <div className="modal-dialog" style={{ marginTop: '3vh' }}>
        <div
          className="modal-content"
          style={{
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '1.5em',
            maxWidth: '500px',
            width: '90%',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            color: '#677793'
          }}>
          {/* Header */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
              <button
                type="button"
                onClick={onBack}
                style={{
                  background: 'none',
                  border: 'none',
                  fontSize: '1.5rem',
                  cursor: 'pointer',
                  color: '#677793 '
                }}>
                <i className="fas fa-arrow-left"></i>
              </button>
              <h5 style={{ margin: 0, fontWeight: '500' }}>Add Offer</h5>
            </div>
            <button
              type="button"
              aria-label="Close"
              onClick={onClose}
              style={{
                background: 'none',
                border: 'none',
                fontSize: '1.5rem',
                cursor: 'pointer',
                color: '#677793'
              }}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          {/* Image Upload Section */}
          <ImageUploader setImageData={setImageData} />

          <div
            style={{
              marginTop: '1.5em',
              display: 'flex',
              flexDirection: 'column',
              gap: '1em'
            }}>
            {/* Offer Title */}
            <div style={{ position: 'relative' }}>
              <label
                htmlFor="offerTitle"
                style={{
                  display: 'block',
                  fontSize: '0.9rem',
                  color: '#677793',
                  marginBottom: '0.3em'
                }}>
                Offer Title
              </label>
              <input
                type="text"
                id="offerTitle"
                onChange={(e) => setOfferTitle(e.target.value)}
                placeholder="Example: 20% off in store or online"
                style={{
                  width: '100%',
                  padding: '0.8em',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  fontSize: '1rem',
                  // color: '#677793',
                  outline: 'none',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              />
            </div>

            {/* Start Date */}
            <div style={{ position: 'relative' }}>
              <label
                htmlFor="startDate"
                style={{
                  display: 'block',
                  fontSize: '0.9rem',
                  color: '#677793',
                  marginBottom: '0.3em'
                }}>
                Start Date
              </label>
              <input
                type="date"
                id="startDate"
                onChange={(e) => setStartDate(e.target.value)}
                style={{
                  width: '100%',
                  padding: '0.8em',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  fontSize: '1rem',
                  color: '#677793',
                  outline: 'none',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              />
            </div>

            {/* End Date */}
            <div style={{ position: 'relative' }}>
              <label
                htmlFor="endDate"
                style={{
                  display: 'block',
                  fontSize: '0.9rem',
                  color: '#677793',
                  marginBottom: '0.3em'
                }}></label>
              <input
                type="date"
                id="endDate"
                onChange={(e) => setEndDate(e.target.value)}
                style={{
                  width: '100%',
                  padding: '0.8em',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  fontSize: '1rem',
                  color: '#677793',
                  outline: 'none',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                }}
              />
            </div>
          </div>

          <RenderBestTimeOffer
            onChange={handleScheduleChange}
            currentClientTimeZone={props.currentClientTimeZone}
            currentClientBestTimes={props.currentClientBestTimes}
          />

          <div>
            <button
              onClick={toggleDropdown}
              className="btn w-100 text-start p-2 d-flex justify-content-between align-items-center"
              aria-expanded={isOpen}
              style={{
                marginTop: '15px',
                backgroundColor: 'transparent',
                color: 'inherit',
                border: 'none'
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = '#e0e0e0')
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = 'transparent')
              }>
              Add more details (optional)
              <span
                className={`ml-2 transform transition ${
                  isOpen ? 'rotate-180' : ''
                }`}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  focusable="false"
                  className="fill-current text-gray-600">
                  <path d="M5.41 7.59L4 9l8 8 8-8-1.41-1.41L12 14.17z"></path>
                </svg>
              </span>
            </button>

            {isOpen && (
              <div className="mt-4">
                <div className="mb-3">
                  <label
                    htmlFor="offerDetails"
                    style={{
                      display: 'block',
                      fontSize: '0.9rem',
                      color: '#677793',
                      marginBottom: '0.3em'
                    }}>
                    Offer details
                  </label>
                  <textarea
                    id="offerDetails"
                    onChange={(e) => setOfferDetails(e.target.value)}
                    rows="3"
                    maxLength="1500"
                    className="form-control"
                    placeholder="Enter offer details (optional)"></textarea>
                  <p className="text-muted small mt-1">
                    0/1500 characters entered
                  </p>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="voucherCode"
                    style={{
                      display: 'block',
                      fontSize: '0.9rem',
                      color: '#677793',
                      marginBottom: '0.3em'
                    }}>
                    Voucher code (optional)
                  </label>
                  <input
                    type="text"
                    id="voucherCode"
                    onChange={(e) => setVoucherCode(e.target.value)}
                    className="form-control"
                    placeholder="Enter voucher code"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="redeemLink"
                    style={{
                      display: 'block',
                      fontSize: '0.9rem',
                      color: '#677793',
                      marginBottom: '0.3em'
                    }}>
                    Link to redeem offer (optional)
                  </label>
                  <input
                    type="url"
                    id="redeemLink"
                    onChange={(e) => setRedeemLink(e.target.value)}
                    className="form-control"
                    placeholder="Enter URL"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="additionalNotes"
                    style={{
                      display: 'block',
                      fontSize: '0.9rem',
                      color: '#677793',
                      marginBottom: '0.3em'
                    }}>
                    Additional notes (optional)
                  </label>
                  <textarea
                    id="additionalNotes"
                    onChange={(e) => setAdditionalNotes(e.target.value)}
                    rows="3"
                    className="form-control"
                    placeholder="Enter any additional notes"></textarea>
                </div>
              </div>
            )}
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OfferModal;
