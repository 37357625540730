import React, { useState, useEffect } from 'react';
import { API_GET_CS_IMAGES } from '../../../utils';
import './UserGallery.css';
import axios from 'axios';
import '../Modal-calendar.css';
import { useSelector } from 'react-redux';
import { selectedClientSelector } from '../../../store/slices/selectedClient';

const ContentGallery = (props) => {
  const { selectedClient } = useSelector(selectedClientSelector);
  const [images, setImages] = useState(null);

  useEffect(() => {
    setImages(null);
  }, [props.userId]);

  useEffect(() => {
    const handleLoadImages = async (imgs) => {
      let x = imgs.reverse().map((data, index) => {
        let urlOriginal = data?.SK;
        return (
          <div
            key={index}
            style={{
              display: 'inline-block',
              margin: 1,
              position: 'relative'
            }}>
            <div>
              <img
                alt={data?.LastModified}
                onClick={() => {
                  let ev = {
                    target: { value: urlOriginal }
                  };
                  if (!props.clientName) {
                    props.setAppImage(ev);
                  }
                }}
                src={urlOriginal}
                style={{
                  height: props.clientName ? 81 : 75,
                  width: props.clientName ? 81 : 75,
                  resizeMode: 'cover'
                }}
              />
            </div>
          </div>
        );
      });

      setImages(x);
    };

    const getImages = async () => {
      if (props.userId) {
        await axios
          .get(`${API_GET_CS_IMAGES}/${selectedClient.contentStrategy}`)
          .then(async (response) => {
            if (response.data.length) {
              let z = response?.data;

              handleLoadImages(z, null);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  return (
    <div>
      <div className="card card-app-photos pd-5">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: props.clientName ? 'space-between' : 'center'
          }}>
          <h5
            style={{ paddingLeft: 10, paddingTop: 10 }}
            className="card-title">
            Content Gallery
          </h5>
        </div>
        <div
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          className="images-wrapper">
          <div className="card-rows">
            {images ? (
              images
            ) : (
              <span className="d-flex align-items-center justify-content-center pd-y-13 tx-gray-400">
                No photos
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContentGallery;
