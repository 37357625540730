'use client';

import { useEffect, useRef } from 'react';

import cx from 'classnames';

import './TagsOnboarding.css';
function TagsInput({
  redTag,
  blueTag,
  setFieldValue,
  id,
  values = [],
  extraValues = [],
  placeholder,
  customStyle
}) {
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [values]);

  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.type === 'blur') {
      e.preventDefault();
      const value = e.target.value;
      if (!value.trim()) return;
      if (!values.includes(value)) {
        setFieldValue(id, [...values, value]);
        e.target.value = '';
      }
    }
  }

  function removeTag(index) {
    setFieldValue(
      id,
      values.filter((el, i) => i !== index)
    );
  }

  return (
    <div
      className="tags-input-container-onboarding"
      style={{
        ...customStyle
      }}>
      <div
        className="tags-container scrollbar-sm"
        style={
          customStyle?.tagsContainer ? { ...customStyle.tagsContainer } : {}
        }
        ref={listRef}>
        {values?.map((tag, index) => (
          <div
            className={cx(['tag-item-onboarding'], {
              'red-tag': redTag,
              'blue-tag': blueTag
            })}
            key={index}>
            <p style={{ whiteSpace: 'pre-wrap', display: 'contents' }}>{tag}</p>
            <div>
              <span className="close-tag" onClick={() => removeTag(index)}>
                &times;
              </span>
            </div>
          </div>
        ))}
      </div>
      <textarea
        onBlur={handleKeyDown}
        onKeyDown={handleKeyDown}
        type="text"
        className="tags-input-onboarding"
        placeholder={placeholder || 'Type a keyword'}
      />
    </div>
  );
}

export default TagsInput;
