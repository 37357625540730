// Adler Social style guide
export const primaryColor = '#3766a8';
export const secondaryColor = '#445268';
export const successColor = '#5cb85c';
export const dangerColor = '#d9534f';
export const warningColor = '#f0ad4e';
export const infoColor = '#5bc0de';

// Calendar color conventions
export const postedColor = '#f9b47f';
export const deleteColor = '#ff0000';
export const scheduledColor = '#039be5';
export const draftColor = '#7987a1';
export const appColor = '#10b759';
export const promptColor = '#B181ED';
export const promoColor = '#5E72E4';
export const offerColor = '#ffca41';
